import { useQrCreateStore } from "~~/stores/qrcreate";
import GetTheme from "~/components/qrcreate/canvas/themes";
import moment from "moment";
interface Session {
  slug: string;
  fileObject?: Array<{
    path: string;
  }>;
  type: string;
}
interface OptionQrCanvas {
  stack?: boolean;
  set_field?: boolean;
}

export const useQrStyle = () => {
  const route = useRoute();
  const utils = useUtils();
  const config = useRuntimeConfig();
  const qrcreate = useQrCreateStore();
  const GenerateQrCode = async (options: OptionQrCanvas = {}) => {
    qrcreate.config.data = await PrepareContent();
    if (qrcreate.config.data) {
      //@ts-ignore
      await drawCanvasTemplate(options);
    }
    SaveConfigVisitor();
    if (options.stack) {
      SaveStackSession();
    }
  };

  const drawCanvasTemplate = async (options: OptionQrCanvas = {}) => {
    if (qrcreate.select_template.id) {
      //@ts-ignore
      var ClassNameTemp = GetTheme(qrcreate.select_template);
      qrcreate.config.width = ClassNameTemp?.sizeQR ?? 600;
      qrcreate.config.height = ClassNameTemp?.sizeQR ?? 600;
      //@ts-ignore
      var qrcanvas = new QRCodeStyling({ ...qrcreate.config });
      var blob = await qrcanvas.getRawData("png");
      var imageData = await utils.readBlobToBase64(blob);

      //@ts-ignore
      var temp = new ClassNameTemp({ image_base64: imageData });
      if (options.set_field) {
        qrcreate.fields = temp.config;
        qrcreate.download.width = temp.width;
        qrcreate.download.height = temp.height;
        qrcreate.download.custom_size = 0;
      } else {
        //@ts-ignore
        temp.config = qrcreate.fields;
      }
      qrcreate.imageData = await temp.buildGraphic();
      if (qrcreate.download.width == qrcreate.download.height) {
        qrcreate.download.width = temp.width;
        qrcreate.download.height = temp.height;
        qrcreate.download.custom_size = 0;
      }
    } else {
      qrcreate.config.width = 600;
      qrcreate.config.height = 600;
      //@ts-ignore
      var qrcanvas = new QRCodeStyling({ ...qrcreate.config });
      var blob = await qrcanvas.getRawData("png");

      qrcreate.imageData = await utils.readBlobToBase64(blob);
      if (qrcreate.download.width != qrcreate.download.height) {
        qrcreate.download.custom_size = 0;
        qrcreate.download.width = 600;
        qrcreate.download.height = 600;
      }
    }
  };

  const PrepareDownload = async (): Promise<{
    is_template: boolean;
    imageData: string;
  }> => {
    if (qrcreate.select_template.id) {
      //@ts-ignore
      var ClassNameTemp = GetTheme(qrcreate.select_template);
      qrcreate.config.width = ClassNameTemp?.sizeQR ?? 600;
      qrcreate.config.height = ClassNameTemp?.sizeQR ?? 600;

      //@ts-ignore
      var qrcanvas = new QRCodeStyling({
        ...qrcreate.config,
        width: qrcreate.download.width,
        height: qrcreate.download.width,
      });
      //@ts-ignore
      var blob = await qrcanvas.getRawData("png");
      var imageData = await utils.readBlobToBase64(blob);

      //@ts-ignore
      var temp = new ClassNameTemp({ image_base64: imageData });
      temp.setConfig(qrcreate.fields);

      //@ts-ignore
      var svg = await temp.getCanvas2Svg();
      const svgDom = document.getElementById("svg-display") as HTMLElement;
      svgDom.innerHTML = "";
      svgDom.appendChild(svg);
      //@ts-ignore
      let session = new SvgTextToPath(svgDom, {
        googleApiKey: config.public.webfont_key,
      });
      await session.replaceAll();
      return {
        is_template: true,
        imageData: "",
      };
    }

    //@ts-ignore
    var qrcanvas = new QRCodeStyling({
      ...qrcreate.config,
      width: qrcreate.download.width,
      height: qrcreate.download.width,
    });
    var blob = await qrcanvas.getRawData("png");
    var imageData = await utils.readBlobToBase64(blob);

    return {
      is_template: false,
      imageData: imageData,
    };
  };

  const PrepareContent = async () => {
    const utils = useUtils();
    var current_type: string | string[] = "text";
    current_type = route.params.type ?? "text";
    switch (current_type) {
      case "text":
        return qrcreate.text.content;
      case "url":
        return qrcreate.url.url;
      case "vcard":
        return qrcreate.vcard.vcard;
      case "image":
        if (!qrcreate.image.slug) {
          return "";
        }
        return  "https://qrgateway.com/q/" + qrcreate.image.slug;
      case "document":
        if (!qrcreate.document.slug) {
          return "";
        }
        return "https://qrgateway.com/q/" + qrcreate.document.slug;
      case "multimedia":
        if (!qrcreate.multimedia.slug) {
          return "";
        }
        return "https://qrgateway.com/q/" + qrcreate.multimedia.slug;
      case "map":
        if (qrcreate.map.id) {
          return "https://search.google.com/local/reviews?placeid="+qrcreate.map.id
        }
        return ``;
      // return `https://maps.google.com/?q=${qrcreate.map.location.lat},${qrcreate.map.location.lng}`;
      case "wifi":
        return `WIFI:S:${qrcreate.wifi.ssid};T:${qrcreate.wifi.encryption};P:${qrcreate.wifi.password};H:${qrcreate.wifi.hidden};;`;
      case "event":
        const name = qrcreate.event.name;
        if (!name) {
          return "";
        }
        const description = qrcreate.event.description;
        const place = qrcreate.event.place;
        const start = qrcreate.event.start
          ? utils.isoDateString(qrcreate.event.start)
          : "";
        const end = qrcreate.event.end
          ? utils.isoDateString(qrcreate.event.end)
          : "";

        if (qrcreate.event.type == "Google Calendar") {
          var params = {
            action: "TEMPLATE",
            sf: true,
            output: "xml",
            text: name,
            details: description,
            location: place,
            dates: "",
          };
          var time_str = [];
          if (start) {
            time_str.push(start);
          }
          if (end) {
            time_str.push(end);
          }
          params.dates = time_str.join("/");
          return (
            "https://www.google.com/calendar/render?" +
            utils.objectToQuery(params)
          );
        } else {
          return `BEGIN:VCALENDAR
                      VERSION:2.0
                      BEGIN:VEVENT
                      CLASS:PUBLIC
                      DESCRIPTION: ${description}
                      DTSTART:${start}
                      DTEND:${end}
                      LOCATION:${place}
                      SUMMARY:${name}
                      TRANSP:TRANSPARENT
                      END:VEVENT
                      END:VCALENDAR
                      UID:QRGateway_1908
                      DTSTAMP:${moment().format("YYYYMMDD")}
                      PRODID:`;
        }
      case "email":
        return (
          "MATMSG:TO:" +
          qrcreate.email.email +
          ";SUB:" +
          qrcreate.email.subject +
          ";BODY:" +
          qrcreate.email.message +
          ";;"
        );
      case "sms":
        return "SMSTO:" + qrcreate.sms.number + ":" + qrcreate.sms.message;
    }
    return "";
  };

  const ClearSession = async () => {
    qrcreate.imageData = "";
  };

  const SaveConfigVisitor = () => {
    if (typeof localStorage !== "undefined") {
      // storage level error,width,height,

      const options = {
        config: qrcreate.config,
        select_template: qrcreate.select_template,
        fields: qrcreate.fields,
      };
      localStorage.setItem("__code_settings", JSON.stringify(options));
    }
  };

  const SaveStackSession = () => {
    var current_type: string | string[] = "text";
    current_type = route.params.type ?? "text";
    if (!qrcreate.imageData) {
      return;
    }
    //@ts-ignore
    var session = {
      type: current_type,
      //@ts-ignore
      data: qrcreate[current_type] ?? "",
      image: qrcreate.imageData,
      created_at: new Date(),
      id: new Date().getTime(),
    };

    if (typeof localStorage !== "undefined") {
      var __sessions_way = localStorage.getItem("__sessions_way");
      var data = [];
      if (__sessions_way) {
        data = JSON.parse(__sessions_way);
        if (Array.isArray(data)) {
          data.unshift(session);
        }
      } else {
        data.unshift(session);
      }
      localStorage.setItem("__sessions_way", JSON.stringify(data));
    }
  };

  const GetStacKSession = (paginate?: number, page?: number): Array<any> => {
    if (typeof localStorage !== "undefined") {
      var __sessions_way = localStorage.getItem("__sessions_way");
      if (__sessions_way) {
        __sessions_way = JSON.parse(__sessions_way);
        if (Array.isArray(__sessions_way)) {
          if (page && paginate) {
            var startIndex = 0;
            var limit = paginate;
            if (page != 1) {
              startIndex = paginate * (page - 1);
              limit = paginate * page + paginate;
            }
          }
          return __sessions_way;
        }
      }
      return [];
    }
    return [];
  };

  const SetConfigStorage = () => {
    if (typeof localStorage !== "undefined") {
      try {
        var codeSettings = localStorage.getItem("__code_settings");
        if (codeSettings) {
          const options: {
            config: Object;
            select_template: Object;
            fields: Object;
          } = JSON.parse(codeSettings);
          if (options.config) {
            //@ts-ignore
            qrcreate.config = options.config;
          }
          if (options.select_template) {
            //@ts-ignore
            qrcreate.select_template = options.select_template;
          }
          if (options.fields) {
            //@ts-ignore
            qrcreate.fields = options.fields;
          }
        }
      } catch (err: any) {}
    }
  };

  const ImportFont = () => {
    const href =
      "https://fonts.googleapis.com/css?family=Arvo:400|Barlow:400|Bebas+Neue:400|Bitter:400|Calistoga:400|Capriola:400|Courgette:400|Crimson+Text:400|DM+Sans:500|EB+Garamond:400|Fredoka+One:400|Gothic+A1:600|Hepta+Slab:400|IBM+Plex Sans:400|IBM+Plex Serif:500|Inter:400|Karla:700|Kite+One:400|Lato:400|Libre+Baskerville:400|Lora:400|Merriweather:400|Montserrat:400|Nanum+Pen Script:400|Noto+Serif:400|Nova+Round:400|Oswald:400|Pacifico:400|Playfair+Display:400|Poppins:400|PT+Serif:400|Quicksand:500|Raleway:400|Roboto:400|Roboto+Slab:400|Rock+Salt:400|Rubik:400|Source+Serif Pro:400|Space+Mono:400|Special+Elite:400|Work+Sans:400|Zilla+Slab:400|Marck+Script:400|Rubik+Wet+Paint:400|Lobster:400|Shrikhand:400&amp;display=swap";
    if (typeof document !== "undefined") {
      if (document.querySelector("link#font-qrstyle")) {
        qrcreate.preload_font = true;
        return;
      }
      var link = document.createElement("link");
      link.setAttribute("href", href);
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.setAttribute("id", "font-qrstyle");
      link.setAttribute("async", "");
      document.body.appendChild(link);
      link.onload = () => {
        qrcreate.preload_font = true;
        console.log("🎷 Loaded Google Font");
      };
    }
  };
  const SaveDownloadFormatConfig = (config: {
    width: number;
    height: number;
    format: string;
    custom_size: number;
  }) => {
    if (typeof localStorage !== "undefined") {
      localStorage.setItem("__download_format", JSON.stringify(config));
    }
  };

  return {
    ClearSession,
    GenerateQrCode,
    SaveStackSession,
    SetConfigStorage,
    ImportFont,
    PrepareDownload,
    GetStacKSession,
    SaveDownloadFormatConfig,
  };
};
