
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 800, height: 584 }
export default class Template11_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 1;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    },
                    background: {
                        label: "Background Color",
                        value: "#00000000",
                        value_second: "#00000000",
                        type: "color",
                        gradient: false
                    }
                },
            }
        }
    }

    static get sizeQR() {
        return 414;
    }


    async buildGraphic() {
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.moveTo(754.3, 538.7);
        this.ctx.lineTo(263.0, 538.7);
        this.ctx.lineTo(263.0, 47.5);
        this.ctx.lineTo(754.3, 47.5);
        this.ctx.lineTo(754.3, 538.7);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.background);
        this.ctx.fill();


        // layer1/Group/Group
        this.ctx.save();

        // layer1/Group/Group/Compound Path
        this.ctx.save();
        this.ctx.beginPath();

        // layer1/Group/Group/Compound Path/Path
        this.ctx.moveTo(781.7, 0.0);
        this.ctx.lineTo(222.4, 0.0);
        this.ctx.lineTo(160.6, 0.0);
        this.ctx.lineTo(40.2, 0.0);
        this.ctx.lineTo(21.1, 0.0);
        this.ctx.lineTo(18.9, 0.0);
        this.ctx.bezierCurveTo(8.5, 0.0, 0.0, 8.4, 0.0, 18.9);
        this.ctx.lineTo(0.0, 565.8);
        this.ctx.bezierCurveTo(0.0, 576.2, 8.5, 584.6, 18.9, 584.6);
        this.ctx.lineTo(21.1, 584.6);
        this.ctx.lineTo(40.2, 584.6);
        this.ctx.lineTo(160.6, 584.6);
        this.ctx.lineTo(195.3, 584.6);
        this.ctx.lineTo(781.7, 584.6);
        this.ctx.bezierCurveTo(792.1, 584.6, 800.6, 576.2, 800.6, 565.8);
        this.ctx.lineTo(800.6, 18.9);
        this.ctx.bezierCurveTo(800.6, 8.4, 792.1, 0.0, 781.7, 0.0);
        this.ctx.closePath();

        // layer1/Group/Group/Compound Path/Path
        this.ctx.moveTo(752.3, 516.6);
        this.ctx.bezierCurveTo(752.3, 526.8, 744.0, 535.1, 733.7, 535.1);
        this.ctx.lineTo(284.6, 535.1);
        this.ctx.bezierCurveTo(274.4, 535.1, 266.0, 526.8, 266.0, 516.6);
        this.ctx.lineTo(266.0, 68.0);
        this.ctx.bezierCurveTo(266.0, 57.8, 274.4, 49.5, 284.6, 49.5);
        this.ctx.lineTo(733.7, 49.5);
        this.ctx.bezierCurveTo(744.0, 49.5, 752.3, 57.8, 752.3, 68.0);
        this.ctx.lineTo(752.3, 516.6);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color);
        this.ctx.fill();

        // layer1/Group/Group/text

        // layer1/Group/Group/text/Group
        this.ctx.save();

        // layer1/Group/Group/text/Group/Group
        this.ctx.save();

        // layer1/Group/Group/text/Group/Group/Compound Path
        this.ctx.save();
        this.ctx.beginPath();

        // layer1/Group/Group/text/Group/Group/Compound Path/Path
        this.ctx.moveTo(42.9, 236.7);
        this.ctx.lineTo(42.9, 234.4);
        this.ctx.lineTo(52.3, 233.4);
        this.ctx.lineTo(52.3, 235.8);
        this.ctx.bezierCurveTo(52.3, 240.5, 54.7, 243.4, 58.6, 243.4);
        this.ctx.bezierCurveTo(62.5, 243.4, 64.9, 241.0, 64.9, 237.2);
        this.ctx.bezierCurveTo(64.9, 233.1, 63.2, 230.2, 55.9, 223.3);
        this.ctx.bezierCurveTo(46.2, 214.2, 43.5, 209.2, 43.5, 202.1);
        this.ctx.bezierCurveTo(43.5, 192.5, 49.0, 186.2, 58.3, 186.2);
        this.ctx.bezierCurveTo(67.6, 186.2, 73.2, 192.5, 73.2, 202.2);
        this.ctx.lineTo(73.2, 203.8);
        this.ctx.lineTo(63.8, 204.9);
        this.ctx.lineTo(63.8, 203.0);
        this.ctx.bezierCurveTo(63.8, 198.0, 61.8, 195.5, 58.4, 195.5);
        this.ctx.bezierCurveTo(55.0, 195.5, 53.1, 197.6, 53.1, 201.6);
        this.ctx.bezierCurveTo(53.1, 205.7, 55.1, 208.6, 62.0, 215.5);
        this.ctx.bezierCurveTo(71.4, 224.9, 74.5, 229.6, 74.5, 236.7);
        this.ctx.bezierCurveTo(74.5, 245.9, 68.0, 252.6, 58.7, 252.6);
        this.ctx.bezierCurveTo(49.4, 252.6, 42.9, 245.9, 42.9, 236.7);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.content.color);
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group/Compound Path
        this.ctx.beginPath();

        // layer1/Group/Group/text/Group/Group/Compound Path/Path
        this.ctx.moveTo(91.2, 237.3);
        this.ctx.lineTo(91.2, 201.5);
        this.ctx.bezierCurveTo(91.2, 192.5, 97.3, 186.2, 106.5, 186.2);
        this.ctx.bezierCurveTo(115.6, 186.2, 121.8, 192.5, 121.8, 201.5);
        this.ctx.lineTo(121.8, 209.1);
        this.ctx.lineTo(112.2, 209.1);
        this.ctx.lineTo(112.2, 201.5);
        this.ctx.bezierCurveTo(112.2, 197.8, 109.9, 195.5, 106.6, 195.5);
        this.ctx.bezierCurveTo(103.2, 195.5, 100.9, 197.8, 100.9, 201.5);
        this.ctx.lineTo(100.9, 237.3);
        this.ctx.bezierCurveTo(100.9, 241.1, 103.2, 243.4, 106.6, 243.4);
        this.ctx.bezierCurveTo(109.9, 243.4, 112.2, 241.1, 112.2, 237.3);
        this.ctx.lineTo(112.2, 228.1);
        this.ctx.lineTo(121.8, 228.1);
        this.ctx.lineTo(121.8, 237.3);
        this.ctx.bezierCurveTo(121.8, 246.3, 115.6, 252.6, 106.5, 252.6);
        this.ctx.bezierCurveTo(97.3, 252.6, 91.2, 246.3, 91.2, 237.3);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group/Compound Path
        this.ctx.beginPath();

        // layer1/Group/Group/text/Group/Group/Compound Path/Path
        this.ctx.moveTo(159.2, 237.4);
        this.ctx.lineTo(148.7, 237.4);
        this.ctx.lineTo(146.6, 251.7);
        this.ctx.lineTo(136.8, 251.7);
        this.ctx.lineTo(147.5, 187.2);
        this.ctx.lineTo(160.6, 187.2);
        this.ctx.lineTo(171.3, 251.7);
        this.ctx.lineTo(161.4, 251.7);
        this.ctx.lineTo(159.2, 237.4);
        this.ctx.closePath();

        // layer1/Group/Group/text/Group/Group/Compound Path/Path
        this.ctx.moveTo(157.9, 228.0);
        this.ctx.lineTo(154.0, 201.0);
        this.ctx.lineTo(150.0, 228.0);
        this.ctx.lineTo(157.9, 228.0);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group/Compound Path
        this.ctx.beginPath();

        // layer1/Group/Group/text/Group/Group/Compound Path/Path
        this.ctx.moveTo(188.1, 187.2);
        this.ctx.lineTo(198.9, 187.2);
        this.ctx.lineTo(211.4, 230.5);
        this.ctx.lineTo(211.4, 187.2);
        this.ctx.lineTo(220.9, 187.2);
        this.ctx.lineTo(220.9, 251.7);
        this.ctx.lineTo(210.6, 251.7);
        this.ctx.lineTo(197.7, 210.4);
        this.ctx.lineTo(197.7, 251.7);
        this.ctx.lineTo(188.1, 251.7);
        this.ctx.lineTo(188.1, 187.2);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group
        this.ctx.restore();

        // layer1/Group/Group/text/Group/Group/Compound Path
        this.ctx.save();
        this.ctx.beginPath();

        // layer1/Group/Group/text/Group/Group/Compound Path/Path
        this.ctx.moveTo(128.8, 273.8);
        this.ctx.lineTo(128.8, 398.4);
        this.ctx.lineTo(109.9, 398.4);
        this.ctx.lineTo(109.9, 312.9);
        this.ctx.lineTo(92.3, 398.4);
        this.ctx.lineTo(79.1, 398.4);
        this.ctx.lineTo(61.4, 312.9);
        this.ctx.lineTo(61.4, 398.4);
        this.ctx.lineTo(42.9, 398.4);
        this.ctx.lineTo(42.9, 273.8);
        this.ctx.lineTo(69.6, 273.8);
        this.ctx.lineTo(86.0, 353.4);
        this.ctx.lineTo(101.7, 273.8);
        this.ctx.lineTo(128.8, 273.8);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.content.color);
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group/Compound Path
        this.ctx.beginPath();

        // layer1/Group/Group/text/Group/Group/Compound Path/Path
        this.ctx.moveTo(167.3, 273.8);
        this.ctx.lineTo(220.9, 273.8);
        this.ctx.lineTo(220.9, 291.6);
        this.ctx.lineTo(186.2, 291.6);
        this.ctx.lineTo(186.2, 326.3);
        this.ctx.lineTo(213.8, 326.3);
        this.ctx.lineTo(213.8, 344.1);
        this.ctx.lineTo(186.2, 344.1);
        this.ctx.lineTo(186.2, 380.6);
        this.ctx.lineTo(220.9, 380.6);
        this.ctx.lineTo(220.9, 398.4);
        this.ctx.lineTo(167.3, 398.4);
        this.ctx.lineTo(167.3, 273.8);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/text/Group
        this.ctx.restore();
        this.ctx.restore();

        // layer1/Group/Group/text/Group/Group
        this.ctx.save();

        // layer1/Group/Group/text/Group/Group/Path
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.moveTo(185.6, 102.3);
        this.ctx.lineTo(159.1, 87.1);
        this.ctx.lineTo(159.1, 117.6);
        this.ctx.lineTo(185.6, 102.3);
        this.ctx.lineTo(159.1, 87.1);
        this.ctx.lineTo(159.1, 117.6);
        this.ctx.lineTo(185.6, 102.3);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.content.color);
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(145.2, 102.3);
        this.ctx.lineTo(118.7, 87.1);
        this.ctx.lineTo(118.7, 117.6);
        this.ctx.lineTo(145.2, 102.3);
        this.ctx.lineTo(118.7, 87.1);
        this.ctx.lineTo(118.7, 117.6);
        this.ctx.lineTo(145.2, 102.3);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(104.7, 102.3);
        this.ctx.lineTo(78.3, 87.1);
        this.ctx.lineTo(78.3, 117.6);
        this.ctx.lineTo(104.7, 102.3);
        this.ctx.lineTo(78.3, 87.1);
        this.ctx.lineTo(78.3, 117.6);
        this.ctx.lineTo(104.7, 102.3);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group
        this.ctx.restore();

        // layer1/Group/Group/text/Group/Group/Path
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.moveTo(185.6, 482.3);
        this.ctx.lineTo(159.1, 467.1);
        this.ctx.lineTo(159.1, 497.6);
        this.ctx.lineTo(185.6, 482.3);
        this.ctx.lineTo(159.1, 467.1);
        this.ctx.lineTo(159.1, 497.6);
        this.ctx.lineTo(185.6, 482.3);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.content.color);
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(145.2, 482.3);
        this.ctx.lineTo(118.7, 467.1);
        this.ctx.lineTo(118.7, 497.6);
        this.ctx.lineTo(145.2, 482.3);
        this.ctx.lineTo(118.7, 467.1);
        this.ctx.lineTo(118.7, 497.6);
        this.ctx.lineTo(145.2, 482.3);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/text/Group/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(104.7, 482.3);
        this.ctx.lineTo(78.3, 467.1);
        this.ctx.lineTo(78.3, 497.6);
        this.ctx.lineTo(104.7, 482.3);
        this.ctx.lineTo(78.3, 467.1);
        this.ctx.lineTo(78.3, 497.6);
        this.ctx.lineTo(104.7, 482.3);
        this.ctx.closePath();
        this.ctx.fill();

        try {
            await this.insertImage({ x: 300, y: 86, size: Template11_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
