
import CanvasEntity from "../canvas.entity";
import canvasTxt from 'canvas-txt'

const { width, height } = { width: 400, height: 540 }
export default class Template04_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 1;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 60,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 6,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    }
                }
            }
        }
    }

    static get sizeQR() {
        return 278;
    }


    async buildGraphic() {
        this.ctx.save();
        this.ctx.beginPath();

        // layer1/Group/Group/Frame/Path
        this.ctx.moveTo(398.6, 313.4);
        this.ctx.lineTo(375.9, 270.2);
        this.ctx.lineTo(375.9, 25.7);
        this.ctx.bezierCurveTo(375.9, 11.5, 364.3, 0.0, 350.2, 0.0);
        this.ctx.lineTo(49.2, 0.0);
        this.ctx.bezierCurveTo(35.1, 0.0, 23.6, 11.5, 23.6, 25.7);
        this.ctx.lineTo(23.6, 270.2);
        this.ctx.lineTo(0.8, 313.4);
        this.ctx.bezierCurveTo(0.3, 314.3, 0.0, 315.4, 0.0, 316.5);
        this.ctx.lineTo(0.0, 532.8);
        this.ctx.bezierCurveTo(0.0, 536.6, 3.0, 539.6, 6.7, 539.6);
        this.ctx.lineTo(392.7, 539.6);
        this.ctx.bezierCurveTo(396.4, 539.6, 399.4, 536.6, 399.4, 532.8);
        this.ctx.lineTo(399.4, 316.5);
        this.ctx.bezierCurveTo(399.4, 315.4, 399.2, 314.3, 398.6, 313.4);
        this.ctx.closePath();

        // layer1/Group/Group/Frame/Path
        this.ctx.moveTo(233.7, 397.9);
        this.ctx.lineTo(204.1, 372.6);
        this.ctx.bezierCurveTo(201.6, 370.5, 197.9, 370.5, 195.3, 372.6);
        this.ctx.lineTo(165.7, 397.9);
        this.ctx.bezierCurveTo(163.5, 399.9, 160.1, 400.1, 157.6, 398.4);
        this.ctx.lineTo(36.8, 317.9);
        this.ctx.lineTo(36.8, 25.7);
        this.ctx.bezierCurveTo(36.8, 18.8, 42.4, 13.2, 49.2, 13.2);
        this.ctx.lineTo(350.2, 13.2);
        this.ctx.bezierCurveTo(357.0, 13.2, 362.6, 18.8, 362.6, 25.7);
        this.ctx.lineTo(362.6, 317.9);
        this.ctx.lineTo(241.8, 398.4);
        this.ctx.bezierCurveTo(239.3, 400.1, 236.0, 399.9, 233.7, 397.9);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color)
        this.ctx.fill();
        this.drawText({ color: this.config.content.color, content: this.config.content.content, x: 15, y: 420, width: 370, height: 100 })

        try {
            await this.insertImage({ x: 60, y: 37, size: Template04_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
