
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 400, height: 532 }
export default class Template05_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 5;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 60,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 6,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    },
                    background: {
                        label: "Background Color",
                        value: "#00000000",
                        value_second: "#00000000",
                        type: "color",
                        gradient: false
                    }
                }
            }
        }
    }

    static get sizeQR() {
        return 284;
    }


    async buildGraphic() {
        this.ctx.save();
        this.ctx.beginPath();

        // layer1/Group/Group/Compound Path/Path
        this.ctx.moveTo(0.0, 94.3);
        this.ctx.lineTo(0.0, 518.8);
        this.ctx.bezierCurveTo(0.0, 525.9, 5.8, 531.6, 12.9, 531.6);
        this.ctx.lineTo(387.1, 531.6);
        this.ctx.bezierCurveTo(394.2, 531.6, 400.0, 525.9, 400.0, 518.8);
        this.ctx.lineTo(400.0, 94.3);
        this.ctx.bezierCurveTo(400.0, 87.2, 394.2, 81.4, 387.1, 81.4);
        this.ctx.lineTo(12.9, 81.4);
        this.ctx.bezierCurveTo(5.8, 81.4, 0.0, 87.2, 0.0, 94.3);
        this.ctx.closePath();

        // layer1/Group/Group/Compound Path/Path
        this.ctx.moveTo(366.1, 434.0);
        this.ctx.bezierCurveTo(366.1, 441.0, 360.5, 446.7, 353.5, 446.7);
        this.ctx.lineTo(46.6, 446.7);
        this.ctx.bezierCurveTo(39.6, 446.7, 33.9, 441.0, 33.9, 434.0);
        this.ctx.lineTo(33.9, 127.1);
        this.ctx.bezierCurveTo(33.9, 120.1, 39.6, 114.5, 46.6, 114.5);
        this.ctx.lineTo(353.5, 114.5);
        this.ctx.bezierCurveTo(360.5, 114.5, 366.1, 120.1, 366.1, 127.1);
        this.ctx.lineTo(366.1, 434.0);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color)
        this.ctx.fill();

        // layer1/Group/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(122.7, 92.4);
        this.ctx.lineTo(122.7, 40.5);
        this.ctx.bezierCurveTo(122.7, 36.4, 126.1, 33.0, 130.3, 33.0);
        this.ctx.lineTo(256.1, 33.0);
        this.ctx.lineTo(269.8, 33.0);
        this.ctx.bezierCurveTo(273.9, 33.0, 277.3, 36.4, 277.3, 40.6);
        this.ctx.lineTo(277.3, 92.4);
        this.ctx.bezierCurveTo(277.3, 96.5, 280.7, 99.9, 284.8, 99.9);
        this.ctx.lineTo(302.8, 99.9);
        this.ctx.bezierCurveTo(306.9, 99.9, 310.3, 96.5, 310.3, 92.4);
        this.ctx.lineTo(310.3, 36.1);
        this.ctx.bezierCurveTo(310.3, 34.1, 309.5, 32.2, 308.1, 30.8);
        this.ctx.lineTo(279.5, 2.2);
        this.ctx.bezierCurveTo(278.1, 0.8, 276.2, 0.0, 274.2, 0.0);
        this.ctx.lineTo(125.9, 0.0);
        this.ctx.bezierCurveTo(123.9, 0.0, 121.9, 0.8, 120.5, 2.2);
        this.ctx.lineTo(91.9, 30.8);
        this.ctx.bezierCurveTo(90.5, 32.2, 89.7, 34.1, 89.7, 36.1);
        this.ctx.lineTo(89.7, 92.4);
        this.ctx.bezierCurveTo(89.7, 96.5, 93.1, 99.9, 97.3, 99.9);
        this.ctx.lineTo(115.2, 99.9);
        this.ctx.bezierCurveTo(119.4, 99.9, 122.7, 96.5, 122.7, 92.4);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Guide
        this.ctx.restore();
        this.ctx.restore();

        // layer1/Guide

        // layer1/Guide

        // layer1/Background
        this.ctx.beginPath();
        this.ctx.moveTo(366.1, 446.7);
        this.ctx.lineTo(33.9, 446.7);
        this.ctx.lineTo(33.9, 114.5);
        this.ctx.lineTo(366.1, 114.5);
        this.ctx.lineTo(366.1, 446.7);
        this.ctx.fillStyle = this.setColor(this.config.frame.background)
        this.ctx.fill();
        this.ctx.closePath();
        this.ctx.restore();
        // insert text
        this.drawText({ color: this.config.content.color, content: this.config.content.content, x: 34, y: 456, width: 332, height: 62 })

        try {
            await this.insertImage({ x: 58, y: 140, size: Template05_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
