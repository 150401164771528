
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 400, height: 310 }
export default class Template06_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 6;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 40,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 6,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    },
                    background: {
                        label: "Background Color",
                        value: "#00000000",
                        value_second: "#00000000",
                        type: "color",
                        gradient: false
                    }
                }
            }
        }
    }

    static get sizeQR() {
        return 204;
    }


    async buildGraphic() {
        this.ctx.save();
        this.ctx.beginPath();

        // layer1/Group/Group/Compound Path/Path
        this.ctx.moveTo(399.0, 276.8);
        this.ctx.lineTo(398.5, 276.4);
        this.ctx.lineTo(377.2, 255.0);
        this.ctx.bezierCurveTo(375.7, 253.6, 375.7, 251.3, 377.2, 249.8);
        this.ctx.lineTo(398.2, 228.8);
        this.ctx.lineTo(398.7, 228.3);
        this.ctx.bezierCurveTo(401.0, 226.0, 399.3, 222.0, 396.1, 222.0);
        this.ctx.lineTo(329.6, 222.0);
        this.ctx.lineTo(329.6, 18.9);
        this.ctx.bezierCurveTo(329.6, 8.5, 321.1, 0.0, 310.7, 0.0);
        this.ctx.lineTo(89.3, 0.0);
        this.ctx.bezierCurveTo(78.9, 0.0, 70.5, 8.5, 70.5, 18.9);
        this.ctx.lineTo(70.5, 222.0);
        this.ctx.lineTo(4.0, 222.0);
        this.ctx.bezierCurveTo(0.7, 222.0, -0.9, 226.0, 1.4, 228.3);
        this.ctx.lineTo(1.8, 228.8);
        this.ctx.lineTo(22.9, 249.8);
        this.ctx.bezierCurveTo(24.3, 251.3, 24.3, 253.6, 22.9, 255.0);
        this.ctx.lineTo(1.5, 276.4);
        this.ctx.lineTo(1.1, 276.8);
        this.ctx.bezierCurveTo(-1.2, 279.1, 0.4, 283.1, 3.7, 283.1);
        this.ctx.lineTo(52.3, 283.1);
        this.ctx.lineTo(52.3, 306.3);
        this.ctx.lineTo(52.3, 306.6);
        this.ctx.bezierCurveTo(52.3, 308.6, 54.0, 310.3, 56.0, 310.3);
        this.ctx.lineTo(56.3, 310.3);
        this.ctx.lineTo(342.6, 310.3);
        this.ctx.lineTo(342.9, 310.3);
        this.ctx.bezierCurveTo(344.9, 310.3, 346.6, 308.6, 346.6, 306.6);
        this.ctx.lineTo(346.6, 306.3);
        this.ctx.lineTo(346.6, 283.1);
        this.ctx.lineTo(396.4, 283.1);
        this.ctx.bezierCurveTo(399.6, 283.1, 401.3, 279.1, 399.0, 276.8);
        this.ctx.closePath();

        // layer1/Group/Group/Compound Path/Path
        this.ctx.moveTo(80.2, 18.9);
        this.ctx.bezierCurveTo(80.2, 13.8, 84.3, 9.7, 89.3, 9.7);
        this.ctx.lineTo(310.7, 9.7);
        this.ctx.bezierCurveTo(315.8, 9.7, 319.9, 13.8, 319.9, 18.9);
        this.ctx.lineTo(319.9, 240.3);
        this.ctx.bezierCurveTo(319.9, 244.6, 316.8, 248.2, 312.8, 249.2);
        this.ctx.lineTo(307.1, 249.2);
        this.ctx.lineTo(304.7, 249.2);
        this.ctx.lineTo(94.2, 249.2);
        this.ctx.lineTo(92.1, 249.2);
        this.ctx.lineTo(87.3, 249.2);
        this.ctx.bezierCurveTo(83.2, 248.2, 80.2, 244.6, 80.2, 240.3);
        this.ctx.lineTo(80.2, 18.9);
        this.ctx.closePath();

        // layer1/Group/Group/Compound Path/Path
        this.ctx.moveTo(56.3, 249.2);
        this.ctx.bezierCurveTo(57.0, 249.2, 57.8, 249.0, 58.4, 248.6);
        this.ctx.lineTo(70.5, 240.5);
        this.ctx.bezierCurveTo(70.5, 243.6, 71.3, 246.6, 72.7, 249.2);
        this.ctx.lineTo(57.4, 249.2);
        this.ctx.lineTo(56.3, 249.2);
        this.ctx.closePath();

        // layer1/Group/Group/Compound Path/Path
        this.ctx.moveTo(342.6, 249.2);
        this.ctx.lineTo(341.8, 249.2);
        this.ctx.lineTo(327.4, 249.2);
        this.ctx.bezierCurveTo(328.7, 246.7, 329.4, 244.0, 329.6, 241.0);
        this.ctx.lineTo(340.8, 248.6);
        this.ctx.bezierCurveTo(341.4, 249.0, 342.2, 249.2, 342.9, 249.2);
        this.ctx.lineTo(342.6, 249.2);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color)
        this.ctx.fill();

        // layer1/Guide
        this.ctx.restore();
        this.ctx.restore();

        // layer1/Guide

        // layer1/Guide

        // layer1/Guide

        // layer1/background
        this.ctx.beginPath();
        this.ctx.moveTo(319.9, 249.4);
        this.ctx.lineTo(80.2, 249.4);
        this.ctx.lineTo(80.2, 9.7);
        this.ctx.lineTo(319.9, 9.7);
        this.ctx.lineTo(319.9, 249.4);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.background)
        this.ctx.fill();
        this.ctx.restore();
        // insert text
        this.drawText({ color: this.config.content.color, content: this.config.content.content, x: 72, y: 257, width: 254, height: 48 })

        try {
            await this.insertImage({ x: 98, y: 28, size: Template06_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
