
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 400, height: 572 }
export default class Template08_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 8;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 60,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 6,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    },
                    background: {
                        label: "Background Color",
                        value: "#00000000",
                        value_second: "#00000000",
                        type: "color",
                        gradient: false
                    }
                },
            }
        }
    }

    static get sizeQR() {
        return 315;
    }


    async buildGraphic() {
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.moveTo(385.2, 385.2);
        this.ctx.lineTo(15.0, 385.2);
        this.ctx.lineTo(15.0, 15.0);
        this.ctx.lineTo(385.2, 15.0);
        this.ctx.lineTo(385.2, 385.2);
        this.ctx.fillStyle = this.setColor(this.config.frame.background)
        this.ctx.fill();
        this.ctx.closePath();
        this.ctx.restore();
        this.ctx.beginPath();

        // layer1/Group/Compound Path/Path
        this.ctx.moveTo(371.0, 0.0);
        this.ctx.lineTo(29.2, 0.0);
        this.ctx.bezierCurveTo(13.1, 0.0, 0.0, 13.1, 0.0, 29.2);
        this.ctx.lineTo(0.0, 371.0);
        this.ctx.bezierCurveTo(0.0, 387.1, 13.1, 400.2, 29.2, 400.2);
        this.ctx.lineTo(371.0, 400.2);
        this.ctx.bezierCurveTo(387.1, 400.2, 400.2, 387.1, 400.2, 371.0);
        this.ctx.lineTo(400.2, 29.2);
        this.ctx.bezierCurveTo(400.2, 13.1, 387.1, 0.0, 371.0, 0.0);
        this.ctx.closePath();

        // layer1/Group/Compound Path/Path
        this.ctx.moveTo(385.2, 371.0);
        this.ctx.bezierCurveTo(385.2, 378.8, 378.8, 385.2, 371.0, 385.2);
        this.ctx.lineTo(29.2, 385.2);
        this.ctx.bezierCurveTo(21.4, 385.2, 15.0, 378.8, 15.0, 371.0);
        this.ctx.lineTo(15.0, 29.2);
        this.ctx.bezierCurveTo(15.0, 21.4, 21.4, 15.0, 29.2, 15.0);
        this.ctx.lineTo(371.0, 15.0);
        this.ctx.bezierCurveTo(378.8, 15.0, 385.2, 21.4, 385.2, 29.2);
        this.ctx.lineTo(385.2, 371.0);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color);;
        this.ctx.fill();

        // layer1/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(371.0, 458.7);
        this.ctx.lineTo(238.5, 458.7);
        this.ctx.lineTo(192.5, 417.3);
        this.ctx.lineTo(146.6, 458.7);
        this.ctx.lineTo(29.2, 458.7);
        this.ctx.bezierCurveTo(13.1, 458.7, 0.0, 471.8, 0.0, 487.8);
        this.ctx.lineTo(0.0, 503.6);
        this.ctx.lineTo(0.0, 537.4);
        this.ctx.lineTo(0.0, 542.1);
        this.ctx.bezierCurveTo(0.0, 558.1, 13.1, 571.2, 29.2, 571.2);
        this.ctx.lineTo(371.0, 571.2);
        this.ctx.bezierCurveTo(387.1, 571.2, 400.2, 558.1, 400.2, 542.1);
        this.ctx.lineTo(400.2, 537.4);
        this.ctx.lineTo(400.2, 503.6);
        this.ctx.lineTo(400.2, 487.8);
        this.ctx.bezierCurveTo(400.2, 471.8, 387.1, 458.7, 371.0, 458.7);
        this.ctx.closePath();
        this.ctx.fill();
        this.ctx.restore();
        
        // insert text
        this.drawText({ color: this.config.content.color, content: this.config.content.content, x: 16, y: 473, width: 370, height: 86 })

        try {
            await this.insertImage({ x: 43, y: 43, size: Template08_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
