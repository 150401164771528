import canvasTxt from "canvas-txt"
export default class CanvasEntity {

    constructor(width, height, image_base64) {
        this.width = width;
        this.height = height;
        this.initCanvas()
        this.image_base64 = image_base64
        this.template_id = 0;
        this.template_type = ""
        this.config = {
        }
    }
    setTitle(title) {
        this.title = title
    }
    initCanvas() {
        this.canvas = document.createElement('canvas')
        this.canvas.width = this.width
        this.canvas.height = this.height
        this.ctx = this.canvas.getContext('2d')
        // this.ctx = new C2S(this.width, this.height)
        // this.canvas = this.ctx.__canvas
    }
    async getCanvas2Svg() {
        this.ctx = new C2S(this.width, this.height)
        await this.buildGraphic()
        return this.ctx.getSvg();
    }
    exportPNG() {
        return this.canvas.toDataURL();
    }
    insertImage({ x, y, size }) {
        return new Promise((resolve, reject) => {
            this.ctx.beginPath()
            var ctx = this.ctx;
            var image = new Image();
            image.src = this.image_base64
            image.onload = function () {
                ctx.drawImage(this, x, y, size, size)
                resolve(true)
            };
            image.onerror = () => {
                reject(false)
            }

        })
    }
    setConfig(config) {
        this.config = JSON.parse(JSON.stringify(config))
        console.log('setconfig',this.config,config);
    }
    setConfigAttribute(key, value = '') {
        if (this.config[key]) {
            switch (this.config[key].type) {
                case 'color':
                    if (!/^#([a-zA-Z0-9]{2}){3,4}/gm.test(value)) {
                        return;
                    }
                    break;
            }
            this.config[key].value = value
        }
    }
    setColor(color) {
        if (color.gradient) {
            var gradient = this.ctx.createLinearGradient(0, 0, this.width, 0)
            gradient.addColorStop(0, color.value)
            gradient.addColorStop(1, color.value_second)
            return gradient
        } else {
            return color.value;
        }
    }
    drawText({ color, content, x, y, width, height }) {
        this.ctx.beginPath()
        const txt = content.value
        // canvasTxt.debug = true;
        canvasTxt.fontSize = content.font_size
        if (content.font_family) {
            canvasTxt.font = content.font_family
        } else {
            canvasTxt.font = "Inter"
        }
        if (content.font_weight) {
            canvasTxt.fontWeight = content.font_weight;
        } else {
            canvasTxt.fontWeight = "bold";
        }
        this.ctx.fillStyle = this.setColor(color)
        this.ctx.letterSpacing = content.font_space + "px";
        var metrics = this.ctx.measureText(txt);
        let actualHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        canvasTxt.drawText(this.ctx, txt, x, y - actualHeight / 2, width, height)
        this.ctx.restore();
    }
}