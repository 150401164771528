//@ts-nocheck
import { defineStore } from "pinia";

interface TextTool {
  content: string;
}
interface UrlTool {
  url: string;
}
interface VCard {
  vcard: string;
}
interface Session {
  slug: string;
  fileObject?: Array<{
    path: string;
  }>;
  type: string;
}
interface CanvasType {
  id?: number;
  name?: string;
  preview?: string;
  type?: string;
}
const gradientSample = {
  type: "linear",
  colorStops: [
    { offset: 0, color: "#6a1a4c" },
    { offset: 1, color: "#ed1299" },
  ],
  rotation: "0",
};

export const useQrCreateStore = defineStore({
  id: "qrcreate-store",
  state: () => {
    const text: TextTool = {
      content: "",
    };
    const url: UrlTool = {
      url: "",
    };
    const vcard: VCard = {
      vcard: "",
    };
    const image: Session = {
      slug: "",
      type: "image",
    };
    const multimedia: Session = {
      slug: "",
      type: "media",
    };
    const document: Session = {
      slug: "",
      type: "document",
    };
    const map: {
      id: string;
      address: string;
      name: string;
    } = {};
    const imageData: string = "";
    const select_template: CanvasType = {};
    const wifi: {
      ssid: string;
      password: string;
      hidden: boolean;
      encryption: string;
    } = {
      ssid: "",
      encryption: "WPA/WPA2",
      hidden: false,
      password: "",
    };
    const event: {
      name: string;
      place: string;
      start: string;
      end: string;
      type: string;
      description: string;
    } = {
      name: "",
      place: "",
      start: "",
      end: "",
      type: "Event Calendar",
      description: "",
    };
    const email: {
      email: string;
      subject: string;
      message: string;
    } = {
      email: "",
      subject: "",
      message: "",
    };
    const sms: {
      number: string;
      message: string;
    } = {
      number: "",
      message: "",
    };
    return {
      text,
      url,
      vcard,
      image,
      multimedia,
      document,
      map,
      imageData,
      wifi,
      event,
      email,
      sms,
      config: {
        width: 600,
        height: 600,
        data: "",
        margin: 0,
        qrOptions: {
          typeNumber: "0",
          mode: "Byte",
          errorCorrectionLevel: "Q",
        },
        dotsOptions: {
          type: "square",
          color: "#000000",
        },
        image: "",
        imageOptions: {
          hideBackgroundDots: true,
          imageSize: 0.4,
          margin: 0,
          rounded: 0,
        },
        cornersSquareOptions: { type: "square" },
        cornersDotOptions: { type: "" },
        backgroundOptions: {
          color: "#fff",
        },
        corner_custom_color: false,
        image_default: false,
        background_default: true,
      },
      status_update: 0,
      fields: {},
      select_template,
      user_styles: [],
      fetch_styles: false,
      modal_config: false,
      modal_style: false,
      modal_embed: false,
      tab_active: "QR Code",
      preload_font: false,
      download: {
        width: 600,
        height: 600,
        format: "png",
        custom_size: 0,
      },
      history_modal: false,
      preview_widget_modal:false,
      first_download_modal:false,
    };
  },
  actions: {
    CreateSession(type: string, files: Array<any>): Promise<Session> {
      const config = useRuntimeConfig();
      const qrstyle = useQrStyle();
      const url = config.public.app_nest_url + "/qrcode/store";
      return new Promise((resolve, reject) => {
        $fetch(url, {
          method: "POST",
          body: {
            type: type,
            files: files.map(item=>item.id),
          },
        })
          .then(async (res: Session) => {
            switch (res.type) {
              case "images":
                this.image = res;
                break;
              case "document":
                this.document = res;
                break;
              case "audio":
              case "video":
              case "multimedia":
                this.multimedia = res;
                break;
            }
            await qrstyle.GenerateQrCode({ stack: true });
            return resolve(res as Session);
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },

    setGradientByKey(context: { key: string; data: any }) {
      const key = context.key;
      const data = context.data;
      if (!this.config[key]) {
        console.error("config editor is not exist " + key);
        return;
      }

      if (!this.config[key].gradient) {
        this.config[key].gradient = {};
        Object.assign(this.config[key].gradient, gradientSample);
      }

      if (!data.mode) {
        data.mode = this.config[key].gradient.type;
      }
      if (typeof data.rotation == "undefined") {
        data.rotation = this.config[key].gradient.rotation;
      }

      if (!data.color1) {
        data.color1 = this.config[key].gradient.colorStops[0].color;
      }
      if (!data.color2) {
        data.color2 = this.config[key].gradient.colorStops[1].color;
      }
      if (!data.color3 && this.config[key].gradient.colorStops[2]) {
        data.color3 = this.config[key].gradient.colorStops[2].color;
      }
      this.config[key].gradient.type = data.mode;
      this.config[key].gradient.rotation = data.rotation;
      var colors = [
        { offset: 0, color: data.color1 },
        { offset: 1, color: data.color2 },
      ];
      if (data.color3) {
        colors = [
          { offset: 0, color: data.color1 },
          { offset: 0.5, color: data.color2 },
          { offset: 1, color: data.color3 },
        ];
      }
      this.config[key].gradient.colorStops = colors;
      delete this.config[key].color;
    },
    async setColorByKey(context: { key: string; color: any }) {
      const key = context.key;
      const color = context.color;
      if (!this.config[key]) {
        console.error("config editor is not exist " + key);
        return;
      }
      this.config[key] = { ...this.config[key], color };
      if (
        key == "dotsOptions" &&
        this.config.image &&
        this.config.image_default
      ) {
        let image = await transformLogoSvg(color, this.config.image);
        this.config.image = image;
      }
      delete this.config[key].gradient;
    },
    setCornerCustomColor(context: { status: boolean }) {
      const status = context.status;
      this.config.corner_custom_color = status;
      var color = this.config.dotsOptions.color;
      var gradient = this.config.dotsOptions.gradient;
      if (!status) {
        delete this.config.cornersDotOptions.gradient;
        delete this.config.cornersSquareOptions.gradient;
        delete this.config.cornersDotOptions.color;
        delete this.config.cornersSquareOptions.color;
      } else {
        if (color) {
          // this.config.cornersDotOptions = {
          //   ...this.config.cornersDotOptions,
          //   color,
          // };
          this.config.cornersSquareOptions = {
            ...this.config.cornersSquareOptions,
            color,
          };

          delete this.config.cornersDotOptions.gradient;
          delete this.config.cornersSquareOptions.gradient;
        }
        if (gradient) {
          // this.config.cornersDotOptions = {
          //   ...this.config.cornersDotOptions,
          //   gradient: gradient,
          // };
          this.config.cornersSquareOptions = {
            ...this.config.cornersSquareOptions,
            gradient: JSON.parse(JSON.stringify(gradient)),
          };
          delete this.config.cornersDotOptions.color;
          delete this.config.cornersSquareOptions.color;
        }
      }
    },
    setImageOptions(data) {
      if (typeof data.hideBackgroundDots == "undefined") {
        data.hideBackgroundDots = this.config.imageOptions.hideBackgroundDots;
      }
      if (typeof data.imageSize == "undefined") {
        data.imageSize = this.config.imageOptions.imageSize;
      }
      if (typeof data.margin == "undefined") {
        data.margin = this.config.imageOptions.margin;
      }
      if (typeof data.rounded == "undefined") {
        data.rounded = this.config.imageOptions.rounded;
      }
      this.config.imageOptions = data;
    },
    async setImage(data) {
      var image = data.image;
      if (this.config.image_default) {
        if (!data.color) {
          data.color = this.config.dotsOptions.color;
        }
        if (data.color) {
          image = await transformLogoSvg(data.color, image);
        }
      }
      this.config.image = image;
    },
  },
  getters: {},
});

const transformLogoSvg = async (color: string, logobase64: string) => {
  var svgElement = await getSvgCode(logobase64);
  //@ts-ignore
  var svgCode = new XMLSerializer().serializeToString(svgElement.rootElement);
  const regex = /fill=\"(\#\w+)\"/gm;
  svgCode = svgCode.replaceAll(regex, `fill="${color}"`);
  svgCode = svgCode.replaceAll("currentColor", color);
  return "data:image/svg+xml;base64," + window.btoa(svgCode);
};

const getSvgCode = (base64: string): Promise<Document> => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", base64);
    xhr.addEventListener("load", function (ev) {
      //@ts-ignore
      var xml = ev.target?.response;
      var dom = new DOMParser();
      var svg = dom.parseFromString(xml, "image/svg+xml");
      return resolve(svg);
    });
    xhr.send(null);
  });
};
