
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 800, height: 952 }
export default class Template12_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 1;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 110,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 9,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    },
                    background: {
                        label: "Background Color",
                        value: "#00000000",
                        value_second: "#00000000",
                        type: "color",
                        gradient: false
                    }
                },
            }
        }
    }

    static get sizeQR() {
        return 530;
    }


    async buildGraphic() {
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.moveTo(695.3, 687.8);
        this.ctx.lineTo(110.7, 687.8);
        this.ctx.lineTo(110.7, 103.2);
        this.ctx.lineTo(695.3, 103.2);
        this.ctx.lineTo(695.3, 687.8);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.background);;
        this.ctx.fill();

        // layer1/Group/Layer 2/Layer 12/FrameBody
        this.ctx.beginPath();

        // layer1/Group/Layer 2/Layer 12/FrameBody/Path
        this.ctx.moveTo(799.4, 173.2);
        this.ctx.bezierCurveTo(797.3, 170.0, 793.0, 169.2, 789.9, 171.4);
        this.ctx.bezierCurveTo(789.9, 171.4, 789.9, 171.4, 789.9, 171.4);
        this.ctx.lineTo(735.4, 207.9);
        this.ctx.lineTo(735.4, 98.4);
        this.ctx.bezierCurveTo(735.4, 80.6, 721.0, 66.1, 703.1, 66.1);
        this.ctx.lineTo(358.9, 66.1);
        this.ctx.lineTo(326.0, 88.5);
        this.ctx.bezierCurveTo(324.7, 88.8, 323.4, 88.8, 322.1, 88.5);
        this.ctx.bezierCurveTo(319.9, 88.6, 317.8, 87.6, 316.4, 85.8);
        this.ctx.bezierCurveTo(314.4, 82.7, 315.2, 78.5, 318.2, 76.3);
        this.ctx.lineTo(322.7, 73.3);
        this.ctx.lineTo(366.1, 45.5);
        this.ctx.bezierCurveTo(369.2, 43.6, 370.2, 39.6, 368.3, 36.4);
        this.ctx.bezierCurveTo(368.3, 36.4, 368.2, 36.3, 368.2, 36.2);
        this.ctx.bezierCurveTo(365.9, 33.4, 361.7, 33.0, 358.9, 35.3);
        this.ctx.bezierCurveTo(358.9, 35.3, 358.9, 35.3, 358.9, 35.3);
        this.ctx.lineTo(310.7, 65.2);
        this.ctx.lineTo(285.3, 65.2);
        this.ctx.lineTo(252.4, 87.3);
        this.ctx.bezierCurveTo(251.2, 87.8, 249.8, 87.8, 248.5, 87.3);
        this.ctx.bezierCurveTo(246.3, 87.2, 244.2, 86.1, 242.9, 84.3);
        this.ctx.bezierCurveTo(240.9, 81.3, 241.7, 77.2, 244.7, 75.1);
        this.ctx.lineTo(317.6, 28.4);
        this.ctx.bezierCurveTo(320.5, 26.1, 321.3, 22.0, 319.4, 18.9);
        this.ctx.bezierCurveTo(317.1, 16.0, 313.0, 15.2, 309.8, 17.1);
        this.ctx.lineTo(236.6, 66.4);
        this.ctx.lineTo(108.3, 66.4);
        this.ctx.bezierCurveTo(90.4, 66.4, 76.0, 80.9, 76.0, 98.7);
        this.ctx.lineTo(76.0, 188.4);
        this.ctx.lineTo(23.0, 223.1);
        this.ctx.bezierCurveTo(20.0, 224.9, 19.0, 228.7, 20.8, 231.7);
        this.ctx.bezierCurveTo(20.9, 231.9, 21.1, 232.2, 21.2, 232.4);
        this.ctx.bezierCurveTo(22.3, 234.3, 24.4, 235.5, 26.6, 235.4);
        this.ctx.bezierCurveTo(27.9, 235.7, 29.2, 235.7, 30.5, 235.4);
        this.ctx.lineTo(76.3, 205.5);
        this.ctx.lineTo(76.3, 205.5);
        this.ctx.lineTo(86.4, 198.6);
        this.ctx.bezierCurveTo(89.4, 196.5, 93.5, 197.2, 95.6, 200.2);
        this.ctx.bezierCurveTo(95.6, 200.3, 95.7, 200.3, 95.7, 200.4);
        this.ctx.bezierCurveTo(97.7, 203.6, 96.9, 207.7, 93.9, 210.0);
        this.ctx.lineTo(76.6, 221.6);
        this.ctx.lineTo(76.6, 243.5);
        this.ctx.lineTo(54.1, 258.4);
        this.ctx.bezierCurveTo(51.1, 260.4, 50.2, 264.5, 52.2, 267.5);
        this.ctx.bezierCurveTo(52.3, 267.6, 52.3, 267.6, 52.3, 267.7);
        this.ctx.bezierCurveTo(53.6, 269.6, 55.7, 270.7, 58.0, 270.7);
        this.ctx.bezierCurveTo(59.3, 270.9, 60.6, 270.9, 61.9, 270.7);
        this.ctx.lineTo(85.2, 255.1);
        this.ctx.bezierCurveTo(88.1, 254.1, 91.2, 255.2, 92.7, 257.8);
        this.ctx.bezierCurveTo(93.7, 260.9, 92.5, 264.2, 89.7, 265.9);
        this.ctx.lineTo(75.4, 275.5);
        this.ctx.lineTo(75.4, 484.8);
        this.ctx.lineTo(3.0, 533.3);
        this.ctx.bezierCurveTo(-0.1, 535.4, -0.9, 539.7, 1.2, 542.8);
        this.ctx.bezierCurveTo(1.2, 542.8, 1.2, 542.8, 1.2, 542.8);
        this.ctx.bezierCurveTo(2.4, 544.8, 4.6, 545.9, 6.9, 545.8);
        this.ctx.bezierCurveTo(8.1, 546.1, 9.3, 546.1, 10.5, 545.8);
        this.ctx.lineTo(89.7, 491.4);
        this.ctx.bezierCurveTo(92.6, 489.8, 96.2, 490.9, 97.8, 493.8);
        this.ctx.bezierCurveTo(100.0, 496.9, 99.2, 501.2, 96.0, 503.3);
        this.ctx.bezierCurveTo(96.0, 503.3, 96.0, 503.3, 96.0, 503.3);
        this.ctx.lineTo(76.0, 516.8);
        this.ctx.lineTo(76.0, 873.3);
        this.ctx.bezierCurveTo(75.8, 890.2, 89.0, 904.3, 105.9, 905.3);
        this.ctx.lineTo(89.7, 917.9);
        this.ctx.bezierCurveTo(86.6, 920.0, 85.8, 924.3, 87.9, 927.4);
        this.ctx.bezierCurveTo(87.9, 927.4, 87.9, 927.4, 87.9, 927.4);
        this.ctx.bezierCurveTo(89.1, 929.4, 91.3, 930.5, 93.6, 930.4);
        this.ctx.bezierCurveTo(94.8, 930.7, 96.0, 930.7, 97.2, 930.4);
        this.ctx.lineTo(170.5, 881.4);
        this.ctx.bezierCurveTo(173.6, 879.4, 177.6, 880.2, 179.8, 883.2);
        this.ctx.bezierCurveTo(181.9, 886.3, 181.1, 890.6, 178.0, 892.7);
        this.ctx.bezierCurveTo(178.0, 892.7, 178.0, 892.7, 178.0, 892.7);
        this.ctx.lineTo(157.0, 906.8);
        this.ctx.lineTo(322.4, 906.8);
        this.ctx.lineTo(304.8, 918.8);
        this.ctx.bezierCurveTo(301.6, 920.9, 300.8, 925.2, 303.0, 928.3);
        this.ctx.bezierCurveTo(303.0, 928.3, 303.0, 928.3, 303.0, 928.3);
        this.ctx.bezierCurveTo(304.3, 930.1, 306.4, 931.1, 308.7, 931.0);
        this.ctx.bezierCurveTo(309.8, 931.3, 311.1, 931.3, 312.2, 931.0);
        this.ctx.lineTo(409.4, 866.4);
        this.ctx.bezierCurveTo(412.6, 864.3, 416.9, 865.1, 419.0, 868.2);
        this.ctx.bezierCurveTo(419.0, 868.2, 419.0, 868.2, 419.0, 868.2);
        this.ctx.bezierCurveTo(421.1, 871.2, 420.4, 875.3, 417.4, 877.4);
        this.ctx.bezierCurveTo(417.3, 877.4, 417.3, 877.5, 417.2, 877.5);
        this.ctx.lineTo(372.1, 907.4);
        this.ctx.lineTo(613.1, 907.4);
        this.ctx.lineTo(598.5, 916.7);
        this.ctx.bezierCurveTo(595.2, 918.7, 594.3, 922.9, 596.3, 926.2);
        this.ctx.bezierCurveTo(596.3, 926.2, 596.3, 926.2, 596.4, 926.2);
        this.ctx.bezierCurveTo(597.6, 928.2, 599.7, 929.5, 602.0, 929.5);
        this.ctx.bezierCurveTo(603.2, 929.8, 604.4, 929.8, 605.6, 929.5);
        this.ctx.lineTo(676.8, 883.5);
        this.ctx.bezierCurveTo(680.0, 881.4, 684.3, 882.3, 686.3, 885.5);
        this.ctx.bezierCurveTo(686.3, 885.5, 686.4, 885.5, 686.4, 885.6);
        this.ctx.bezierCurveTo(688.1, 888.7, 687.2, 892.7, 684.3, 894.8);
        this.ctx.lineTo(662.8, 908.9);
        this.ctx.lineTo(704.0, 908.9);
        this.ctx.bezierCurveTo(721.9, 908.9, 736.3, 894.4, 736.3, 876.6);
        this.ctx.bezierCurveTo(736.3, 876.5, 736.3, 876.4, 736.3, 876.3);
        this.ctx.lineTo(736.3, 584.1);
        this.ctx.lineTo(722.6, 593.7);
        this.ctx.bezierCurveTo(721.3, 593.9, 720.0, 593.9, 718.7, 593.7);
        this.ctx.bezierCurveTo(716.5, 593.7, 714.3, 592.7, 713.0, 591.0);
        this.ctx.bezierCurveTo(711.0, 587.8, 711.8, 583.6, 714.8, 581.4);
        this.ctx.lineTo(792.3, 529.4);
        this.ctx.bezierCurveTo(795.3, 527.3, 796.1, 523.2, 794.0, 520.1);
        this.ctx.bezierCurveTo(792.0, 517.0, 787.8, 516.2, 784.8, 518.3);
        this.ctx.lineTo(735.4, 550.9);
        this.ctx.lineTo(735.4, 290.1);
        this.ctx.lineTo(721.7, 299.4);
        this.ctx.bezierCurveTo(720.5, 299.9, 719.1, 300.2, 717.8, 300.3);
        this.ctx.bezierCurveTo(715.5, 300.2, 713.4, 299.1, 712.1, 297.3);
        this.ctx.bezierCurveTo(710.0, 294.3, 710.8, 290.2, 713.7, 288.1);
        this.ctx.bezierCurveTo(713.8, 288.1, 713.8, 288.1, 713.9, 288.0);
        this.ctx.lineTo(735.1, 273.4);
        this.ctx.lineTo(735.1, 273.4);
        this.ctx.lineTo(778.8, 243.5);
        this.ctx.bezierCurveTo(781.6, 240.9, 781.9, 236.6, 779.3, 233.7);
        this.ctx.bezierCurveTo(777.3, 231.5, 774.1, 230.8, 771.3, 232.1);
        this.ctx.lineTo(735.4, 256.3);
        this.ctx.lineTo(735.4, 240.5);
        this.ctx.lineTo(721.7, 249.7);
        this.ctx.bezierCurveTo(720.4, 250.1, 719.1, 250.1, 717.8, 249.7);
        this.ctx.bezierCurveTo(715.5, 249.7, 713.4, 248.6, 712.1, 246.7);
        this.ctx.bezierCurveTo(710.0, 243.8, 710.8, 239.7, 713.7, 237.6);
        this.ctx.bezierCurveTo(713.8, 237.5, 713.8, 237.5, 713.9, 237.5);
        this.ctx.lineTo(735.1, 223.1);
        this.ctx.lineTo(735.1, 223.1);
        this.ctx.lineTo(797.0, 181.5);
        this.ctx.bezierCurveTo(799.7, 179.7, 800.7, 176.2, 799.4, 173.2);
        this.ctx.closePath();

        // layer1/Group/Layer 2/Layer 12/FrameBody/Path
        this.ctx.moveTo(693.6, 669.3);
        this.ctx.bezierCurveTo(693.6, 678.4, 686.2, 685.8, 677.1, 685.8);
        this.ctx.bezierCurveTo(677.0, 685.8, 676.9, 685.8, 676.8, 685.8);
        this.ctx.lineTo(130.1, 685.8);
        this.ctx.bezierCurveTo(121.2, 685.9, 113.8, 678.9, 113.7, 669.9);
        this.ctx.bezierCurveTo(113.7, 669.7, 113.7, 669.5, 113.7, 669.3);
        this.ctx.lineTo(113.7, 122.9);
        this.ctx.bezierCurveTo(113.5, 113.8, 120.7, 106.3, 129.8, 106.2);
        this.ctx.bezierCurveTo(129.9, 106.2, 130.0, 106.2, 130.1, 106.2);
        this.ctx.lineTo(676.5, 106.2);
        this.ctx.bezierCurveTo(685.8, 106.2, 693.3, 113.7, 693.3, 122.9);
        this.ctx.lineTo(693.3, 669.3);
        this.ctx.lineTo(693.6, 669.3);
        this.ctx.closePath();

        // layer1/Group/Layer 2/Layer 12/FrameBody/Path
        this.ctx.moveTo(390.9, 31.1);
        this.ctx.bezierCurveTo(388.6, 31.1, 386.5, 30.0, 385.2, 28.1);
        this.ctx.bezierCurveTo(383.1, 25.1, 383.9, 21.0, 386.8, 19.0);
        this.ctx.bezierCurveTo(386.9, 18.9, 387.0, 18.9, 387.0, 18.9);
        this.ctx.lineTo(411.2, 1.2);
        this.ctx.bezierCurveTo(414.4, -0.9, 418.6, -0.1, 420.8, 3.0);
        this.ctx.bezierCurveTo(420.8, 3.0, 420.8, 3.0, 420.8, 3.0);
        this.ctx.bezierCurveTo(423.2, 5.9, 422.8, 10.3, 419.9, 12.7);
        this.ctx.bezierCurveTo(419.5, 13.0, 419.1, 13.2, 418.7, 13.5);
        this.ctx.lineTo(394.5, 31.1);
        this.ctx.bezierCurveTo(393.3, 31.5, 392.1, 31.5, 390.9, 31.1);
        this.ctx.closePath();

        // layer1/Group/Layer 2/Layer 12/FrameBody/Path
        this.ctx.moveTo(29.9, 591.3);
        this.ctx.bezierCurveTo(27.6, 591.3, 25.5, 590.2, 24.2, 588.3);
        this.ctx.bezierCurveTo(22.2, 585.3, 22.9, 581.2, 25.9, 579.1);
        this.ctx.bezierCurveTo(25.9, 579.1, 26.0, 579.1, 26.0, 579.0);
        this.ctx.lineTo(55.9, 559.6);
        this.ctx.bezierCurveTo(58.9, 557.5, 63.0, 558.2, 65.1, 561.2);
        this.ctx.bezierCurveTo(65.1, 561.3, 65.2, 561.3, 65.2, 561.4);
        this.ctx.bezierCurveTo(67.4, 564.5, 66.6, 568.8, 63.4, 570.9);
        this.ctx.bezierCurveTo(63.4, 570.9, 63.4, 570.9, 63.4, 570.9);
        this.ctx.lineTo(33.5, 590.1);
        this.ctx.bezierCurveTo(32.5, 590.8, 31.2, 591.3, 29.9, 591.3);
        this.ctx.closePath();

        // layer1/Group/Layer 2/Layer 12/FrameBody/Path
        this.ctx.moveTo(29.9, 886.2);
        this.ctx.bezierCurveTo(26.1, 886.2, 23.0, 883.1, 23.0, 879.3);
        this.ctx.bezierCurveTo(23.0, 877.0, 24.1, 874.9, 26.0, 873.6);
        this.ctx.lineTo(55.9, 854.5);
        this.ctx.bezierCurveTo(59.0, 852.4, 63.1, 853.2, 65.2, 856.3);
        this.ctx.bezierCurveTo(67.3, 859.3, 66.5, 863.5, 63.4, 865.5);
        this.ctx.lineTo(63.4, 865.5);
        this.ctx.lineTo(33.5, 885.0);
        this.ctx.bezierCurveTo(32.4, 885.6, 31.2, 886.0, 29.9, 886.2);
        this.ctx.closePath();

        // layer1/Group/Layer 2/Layer 12/FrameBody/Path
        this.ctx.moveTo(763.8, 471.3);
        this.ctx.bezierCurveTo(761.6, 471.3, 759.5, 470.2, 758.2, 468.4);
        this.ctx.bezierCurveTo(756.1, 465.4, 756.8, 461.3, 759.8, 459.2);
        this.ctx.bezierCurveTo(759.8, 459.2, 759.9, 459.1, 760.0, 459.1);
        this.ctx.lineTo(783.0, 443.5);
        this.ctx.bezierCurveTo(786.1, 441.4, 790.4, 442.2, 792.5, 445.3);
        this.ctx.bezierCurveTo(792.5, 445.3, 792.5, 445.3, 792.6, 445.3);
        this.ctx.bezierCurveTo(794.6, 448.3, 793.9, 452.4, 790.9, 454.5);
        this.ctx.bezierCurveTo(790.9, 454.5, 790.8, 454.6, 790.8, 454.6);
        this.ctx.lineTo(767.7, 470.2);
        this.ctx.bezierCurveTo(766.5, 470.8, 765.2, 471.2, 763.8, 471.3);
        this.ctx.closePath();

        // layer1/Group/Layer 2/Layer 12/FrameBody/Path
        this.ctx.moveTo(524.6, 949.9);
        this.ctx.bezierCurveTo(522.3, 949.8, 520.2, 948.7, 518.9, 946.9);
        this.ctx.bezierCurveTo(516.9, 943.7, 517.7, 939.5, 520.7, 937.3);
        this.ctx.lineTo(544.0, 922.1);
        this.ctx.bezierCurveTo(547.1, 920.0, 551.2, 920.8, 553.3, 923.8);
        this.ctx.bezierCurveTo(555.4, 926.9, 554.6, 931.1, 551.5, 933.1);
        this.ctx.lineTo(528.5, 948.7);
        this.ctx.bezierCurveTo(527.3, 949.9, 525.9, 950.8, 524.3, 951.4);
        this.ctx.lineTo(524.6, 949.9);
        this.ctx.closePath();

        // layer1/Group/Layer 2/Layer 12/FrameBody/Path
        this.ctx.moveTo(752.5, 748.9);
        this.ctx.bezierCurveTo(750.2, 748.9, 748.1, 747.8, 746.8, 745.9);
        this.ctx.bezierCurveTo(744.7, 742.9, 745.4, 738.8, 748.4, 736.7);
        this.ctx.bezierCurveTo(748.5, 736.7, 748.5, 736.7, 748.6, 736.6);
        this.ctx.lineTo(771.6, 721.1);
        this.ctx.bezierCurveTo(774.8, 719.1, 779.0, 719.9, 781.2, 722.9);
        this.ctx.bezierCurveTo(783.0, 726.1, 782.3, 730.1, 779.4, 732.4);
        this.ctx.lineTo(756.1, 747.7);
        this.ctx.bezierCurveTo(755.1, 748.5, 753.8, 749.0, 752.5, 748.9);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color);
        this.ctx.fill();

        // layer1/Guide
        this.ctx.restore();
        // insert text
        this.drawText({ color: this.config.content.color, content: this.config.content.content, x: 114, y: 719, width: 580, height: 134 })

        try {
            await this.insertImage({ x: 137, y: 130, size: Template12_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
