
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 400, height: 500 }
export default class Template09_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 9;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 60,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 6,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    },
                    background: {
                        label: "Background Color",
                        value: "#00000000",
                        value_second: "#00000000",
                        type: "color",
                        gradient: false
                    }
                },
            }
        }
    }

    static get sizeQR() {
        return 315;
    }


    async buildGraphic() {
        this.ctx.save();
         // layer1/Background
         this.ctx.beginPath();
         this.ctx.moveTo(385, 387);
         this.ctx.lineTo(15.0, 387);
         this.ctx.lineTo(15.0, 15.0);
         this.ctx.lineTo(385, 15.0);
         this.ctx.lineTo(385, 387);
         this.ctx.fillStyle = this.setColor(this.config.frame.background);
         this.ctx.fill();
         this.ctx.closePath();
         this.ctx.restore();
        this.ctx.beginPath();
        
        // layer1/Group/Compound Path/Path
        this.ctx.moveTo(370.5, 0.0);
        this.ctx.lineTo(29.1, 0.0);
        this.ctx.bezierCurveTo(13.1, 0.0, 0.0, 13.1, 0.0, 29.1);
        this.ctx.lineTo(0.0, 345.4);
        this.ctx.lineTo(0.0, 370.5);
        this.ctx.bezierCurveTo(0.0, 386.9, 0.0, 406.9, 0.0, 415.6);
        this.ctx.lineTo(0.0, 431.4);
        this.ctx.lineTo(0.0, 465.1);
        this.ctx.lineTo(0.0, 469.7);
        this.ctx.bezierCurveTo(0.0, 485.8, 13.1, 498.8, 29.1, 498.8);
        this.ctx.lineTo(370.5, 498.8);
        this.ctx.bezierCurveTo(386.6, 498.8, 399.6, 485.8, 399.6, 469.7);
        this.ctx.lineTo(399.6, 465.1);
        this.ctx.lineTo(399.6, 431.4);
        this.ctx.lineTo(399.6, 415.6);
        this.ctx.bezierCurveTo(399.6, 406.2, 399.6, 385.8, 399.6, 370.5);
        this.ctx.lineTo(399.6, 351.3);
        this.ctx.lineTo(399.6, 29.1);
        this.ctx.bezierCurveTo(399.6, 13.1, 386.6, 0.0, 370.5, 0.0);
        this.ctx.closePath();

        // layer1/Group/Compound Path/Path
        this.ctx.moveTo(15.0, 29.1);
        this.ctx.bezierCurveTo(15.0, 21.3, 21.3, 15.0, 29.1, 15.0);
        this.ctx.lineTo(370.5, 15.0);
        this.ctx.bezierCurveTo(378.3, 15.0, 384.6, 21.3, 384.6, 29.1);
        this.ctx.lineTo(384.6, 370.5);
        this.ctx.bezierCurveTo(384.6, 378.3, 378.3, 384.6, 370.5, 384.6);
        this.ctx.lineTo(29.1, 384.6);
        this.ctx.bezierCurveTo(21.3, 384.6, 15.0, 378.3, 15.0, 370.5);
        this.ctx.lineTo(15.0, 29.1);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color);
        this.ctx.fill();

        // layer1/Guide
        this.ctx.restore();

        // layer1/Guide

        // layer1/Guide

       
        // insert text
        this.drawText({ color: this.config.content.color, content: this.config.content.content, x: 15, y: 397, width: 369, height: 87 })

        try {
            await this.insertImage({ x: 42, y: 42, size: Template09_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
