
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 400, height: 498 }
export default class Template07_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 1;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 42,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 6,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    },
                    background: {
                        label: "Background Color",
                        value: "#00000000",
                        value_second: "#00000000",
                        type: "color",
                        gradient: false
                    }
                },
            }
        }
    }

    static get sizeQR() {
        return 315;
    }


    async buildGraphic() {
      this.ctx.save();
       // layer1/background
       this.ctx.beginPath();
       this.ctx.moveTo(386, 386);
       this.ctx.lineTo(15.0, 386);
       this.ctx.lineTo(15.0, 15.0);
       this.ctx.lineTo(386, 15.0);
       this.ctx.lineTo(386, 386);
       this.ctx.fillStyle = this.setColor(this.config.frame.background)
       this.ctx.fill();
       this.ctx.closePath();
       this.ctx.restore();
      this.ctx.beginPath();

      // layer1/Group/Group/Group/Compound Path/Path
      this.ctx.moveTo(371.0, 0.0);
      this.ctx.lineTo(29.1, 0.0);
      this.ctx.bezierCurveTo(13.1, 0.0, 0.0, 13.1, 0.0, 29.1);
      this.ctx.lineTo(0.0, 371.0);
      this.ctx.bezierCurveTo(0.0, 387.0, 13.1, 400.1, 29.1, 400.1);
      this.ctx.lineTo(371.0, 400.1);
      this.ctx.bezierCurveTo(387.0, 400.1, 400.1, 387.0, 400.1, 371.0);
      this.ctx.lineTo(400.1, 29.1);
      this.ctx.bezierCurveTo(400.1, 13.1, 387.0, 0.0, 371.0, 0.0);
      this.ctx.closePath();

      // layer1/Group/Group/Group/Compound Path/Path
      this.ctx.moveTo(385.1, 371.0);
      this.ctx.bezierCurveTo(385.1, 378.8, 378.8, 385.1, 371.0, 385.1);
      this.ctx.lineTo(29.1, 385.1);
      this.ctx.bezierCurveTo(21.4, 385.1, 15.0, 378.8, 15.0, 371.0);
      this.ctx.lineTo(15.0, 29.1);
      this.ctx.bezierCurveTo(15.0, 21.4, 21.4, 15.0, 29.1, 15.0);
      this.ctx.lineTo(371.0, 15.0);
      this.ctx.bezierCurveTo(378.8, 15.0, 385.1, 21.4, 385.1, 29.1);
      this.ctx.lineTo(385.1, 371.0);
      this.ctx.closePath();
      this.ctx.fillStyle = this.setColor(this.config.frame.color);
      this.ctx.fill();

      // layer1/Group/Group/Group/Path
      this.ctx.beginPath();
      this.ctx.moveTo(371.0, 385.1);
      this.ctx.lineTo(29.1, 385.1);
      this.ctx.bezierCurveTo(13.1, 385.1, 0.0, 398.2, 0.0, 414.2);
      this.ctx.lineTo(0.0, 430.0);
      this.ctx.lineTo(0.0, 463.8);
      this.ctx.lineTo(0.0, 468.4);
      this.ctx.bezierCurveTo(0.0, 484.5, 13.1, 497.6, 29.1, 497.6);
      this.ctx.lineTo(371.0, 497.6);
      this.ctx.bezierCurveTo(387.0, 497.6, 400.1, 484.5, 400.1, 468.4);
      this.ctx.lineTo(400.1, 463.8);
      this.ctx.lineTo(400.1, 430.0);
      this.ctx.lineTo(400.1, 414.2);
      this.ctx.bezierCurveTo(400.1, 398.2, 387.0, 385.1, 371.0, 385.1);
      this.ctx.closePath();
      this.ctx.fill();

      // layer1/Group/Group/Group/backgroundmobile
      this.ctx.beginPath();
      this.ctx.moveTo(29.1, 482.6);
      this.ctx.bezierCurveTo(21.4, 482.6, 15.0, 476.2, 15.0, 468.4);
      this.ctx.lineTo(15.0, 414.2);
      this.ctx.bezierCurveTo(15.0, 406.4, 21.4, 400.1, 29.1, 400.1);
      this.ctx.lineTo(94.9, 400.1);
      this.ctx.lineTo(94.9, 482.6);
      this.ctx.lineTo(29.1, 482.6);
      this.ctx.closePath();
      this.ctx.fillStyle = "rgb(255, 254, 255)";
      this.ctx.fill();

      // layer1/Group/Group/Group/Rectangle
      this.ctx.beginPath();
      this.ctx.moveTo(102.4, 485.9);
      this.ctx.lineTo(87.4, 485.9);
      this.ctx.lineTo(87.4, 398.4);
      this.ctx.lineTo(102.4, 398.4);
      this.ctx.lineTo(102.4, 485.9);
      this.ctx.closePath();
      this.ctx.fillStyle = this.setColor(this.config.frame.color);
      this.ctx.fill();

      // layer1/Group/Group/Group/Compound Path
      this.ctx.beginPath();

      // layer1/Group/Group/Group/Compound Path/Path
      this.ctx.moveTo(59.1, 410.6);
      this.ctx.lineTo(48.7, 410.6);
      this.ctx.lineTo(48.1, 410.6);
      this.ctx.lineTo(39.7, 410.6);
      this.ctx.bezierCurveTo(34.1, 410.6, 29.6, 415.1, 29.6, 420.7);
      this.ctx.lineTo(29.6, 462.0);
      this.ctx.bezierCurveTo(29.6, 467.5, 34.1, 472.0, 39.7, 472.0);
      this.ctx.lineTo(48.1, 472.0);
      this.ctx.lineTo(48.7, 472.0);
      this.ctx.lineTo(59.1, 472.0);
      this.ctx.bezierCurveTo(64.6, 472.0, 69.1, 467.5, 69.1, 462.0);
      this.ctx.lineTo(69.1, 420.7);
      this.ctx.bezierCurveTo(69.1, 415.1, 64.6, 410.6, 59.1, 410.6);
      this.ctx.closePath();

      // layer1/Group/Group/Group/Compound Path/Path
      this.ctx.moveTo(39.7, 414.2);
      this.ctx.lineTo(48.1, 414.2);
      this.ctx.lineTo(48.7, 414.2);
      this.ctx.lineTo(59.1, 414.2);
      this.ctx.bezierCurveTo(62.6, 414.2, 65.5, 417.1, 65.5, 420.7);
      this.ctx.lineTo(65.5, 457.5);
      this.ctx.lineTo(48.7, 457.5);
      this.ctx.lineTo(48.1, 457.5);
      this.ctx.lineTo(33.2, 457.5);
      this.ctx.lineTo(33.2, 420.7);
      this.ctx.bezierCurveTo(33.2, 417.1, 36.1, 414.2, 39.7, 414.2);
      this.ctx.closePath();
      this.ctx.fill();

      // layer1/Group/Group/Group/Path
      this.ctx.beginPath();
      this.ctx.moveTo(49.4, 466.9);
      this.ctx.bezierCurveTo(48.2, 466.9, 47.3, 465.9, 47.3, 464.8);
      this.ctx.bezierCurveTo(47.3, 463.6, 48.2, 462.7, 49.4, 462.7);
      this.ctx.bezierCurveTo(50.5, 462.7, 51.4, 463.6, 51.4, 464.8);
      this.ctx.bezierCurveTo(51.4, 465.9, 50.5, 466.9, 49.4, 466.9);
      this.ctx.closePath();
      this.ctx.fillStyle = "rgb(255, 254, 255)";
      this.ctx.fill();

      // layer1/Group/Group/Group/Path
      this.ctx.beginPath();
      this.ctx.moveTo(50.6, 416.8);
      this.ctx.bezierCurveTo(50.1, 416.8, 45.3, 416.8, 44.8, 416.8);
      this.ctx.bezierCurveTo(44.2, 416.8, 43.7, 417.3, 43.7, 417.9);
      this.ctx.bezierCurveTo(43.7, 418.6, 44.2, 419.1, 44.8, 419.1);
      this.ctx.bezierCurveTo(45.3, 419.1, 50.1, 419.1, 50.6, 419.1);
      this.ctx.bezierCurveTo(51.2, 419.1, 51.7, 418.6, 51.7, 417.9);
      this.ctx.bezierCurveTo(51.7, 417.3, 51.2, 416.8, 50.6, 416.8);
      this.ctx.closePath();
      this.ctx.fillStyle = this.setColor(this.config.frame.color);
      this.ctx.fill();

      // layer1/Group/Group/Path
      this.ctx.restore();
      this.ctx.beginPath();
      this.ctx.moveTo(55.1, 417.9);
      this.ctx.bezierCurveTo(55.1, 418.6, 54.5, 419.1, 53.9, 419.1);
      this.ctx.bezierCurveTo(53.3, 419.1, 52.8, 418.6, 52.8, 417.9);
      this.ctx.bezierCurveTo(52.8, 417.3, 53.3, 416.8, 53.9, 416.8);
      this.ctx.bezierCurveTo(54.5, 416.8, 55.1, 417.3, 55.1, 417.9);
      this.ctx.closePath();
      this.ctx.fillStyle = this.setColor(this.config.frame.color);
      this.ctx.fill();

      // layer1/Guide
      this.ctx.restore();
      this.ctx.restore();

      // layer1/Guide

      // layer1/Guide

     
        // insert text
        this.drawText({color: this.config.content.color, content: this.config.content.content, x: 102, y: 400, width: 282, height: 82 })

        try {
            await this.insertImage({ x: 42, y: 43, size: Template07_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
