
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 400, height: 544 }
export default class Template02_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 1;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 60,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 6,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    }
                }
            }
        }
    }

    static get sizeQR() {
        return 282;
    }


    async buildGraphic() {
        this.ctx.save();
        this.ctx.beginPath();

        // layer1/Group/Group/Frame/Path
        this.ctx.moveTo(386.2, 0.0);
        this.ctx.lineTo(12.9, 0.0);
        this.ctx.bezierCurveTo(5.8, 0.0, 0.0, 5.8, 0.0, 12.9);
        this.ctx.lineTo(0.0, 394.2);
        this.ctx.lineTo(0.0, 436.3);
        this.ctx.lineTo(0.0, 531.4);
        this.ctx.bezierCurveTo(0.0, 540.1, 8.9, 546.0, 17.0, 542.6);
        this.ctx.lineTo(194.7, 466.3);
        this.ctx.bezierCurveTo(197.8, 465.0, 201.3, 465.0, 204.3, 466.3);
        this.ctx.lineTo(382.1, 542.6);
        this.ctx.bezierCurveTo(390.1, 546.0, 399.1, 540.1, 399.1, 531.4);
        this.ctx.lineTo(399.1, 436.3);
        this.ctx.lineTo(399.1, 412.6);
        this.ctx.lineTo(399.1, 12.9);
        this.ctx.bezierCurveTo(399.1, 5.8, 393.3, 0.0, 386.2, 0.0);
        this.ctx.closePath();

        // layer1/Group/Group/Frame/Path
        this.ctx.moveTo(33.8, 45.6);
        this.ctx.bezierCurveTo(33.8, 38.6, 39.5, 32.9, 46.4, 32.9);
        this.ctx.lineTo(352.6, 32.9);
        this.ctx.bezierCurveTo(359.6, 32.9, 365.3, 38.6, 365.3, 45.6);
        this.ctx.lineTo(365.3, 351.8);
        this.ctx.bezierCurveTo(365.3, 358.7, 359.6, 364.4, 352.6, 364.4);
        this.ctx.lineTo(46.4, 364.4);
        this.ctx.bezierCurveTo(39.5, 364.4, 33.8, 358.7, 33.8, 351.8);
        this.ctx.lineTo(33.8, 45.6);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color)
        this.ctx.fill();
        // insert text
        this.drawText({ color:this.config.content.color,content: this.config.content.content, x: 34, y: 376, width: 330, height: 80 })

        try {
            await this.insertImage({ x: 58, y: 58, size: Template02_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
