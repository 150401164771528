
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 800, height: 833 }
export default class Template10_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 1;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "Scan me",
                        font_size: 130,
                        font_family: "Marck Script",
                        font_weight: "Bold",
                        font_space: 9,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    },

                },
            }
        }
    }

    static get sizeQR() {
        return 533;
    }


    async buildGraphic() {
        this.ctx.save();
        this.ctx.beginPath();
        this.ctx.moveTo(628.4, 181.3);
        this.ctx.bezierCurveTo(641.6, 181.3, 652.3, 192.0, 652.3, 205.2);
        this.ctx.lineTo(652.3, 265.9);
        this.ctx.lineTo(677.8, 265.9);
        this.ctx.lineTo(677.8, 205.2);
        this.ctx.bezierCurveTo(677.8, 178.0, 655.6, 155.8, 628.4, 155.8);
        this.ctx.lineTo(567.7, 155.8);
        this.ctx.lineTo(567.7, 181.3);
        this.ctx.lineTo(628.4, 181.3);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color)
        this.ctx.fill();

        // layer1/Group/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(49.4, 808.2);
        this.ctx.bezierCurveTo(36.2, 808.2, 25.4, 797.4, 25.4, 784.2);
        this.ctx.lineTo(25.4, 723.5);
        this.ctx.lineTo(0.0, 723.5);
        this.ctx.lineTo(0.0, 784.2);
        this.ctx.bezierCurveTo(0.0, 811.5, 22.1, 833.6, 49.4, 833.6);
        this.ctx.lineTo(110.1, 833.6);
        this.ctx.lineTo(110.1, 808.2);
        this.ctx.lineTo(49.4, 808.2);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(25.4, 205.2);
        this.ctx.bezierCurveTo(25.4, 192.0, 36.2, 181.3, 49.4, 181.3);
        this.ctx.lineTo(110.1, 181.3);
        this.ctx.lineTo(110.1, 155.8);
        this.ctx.lineTo(49.4, 155.8);
        this.ctx.bezierCurveTo(22.1, 155.8, 0.0, 178.0, 0.0, 205.2);
        this.ctx.lineTo(0.0, 265.9);
        this.ctx.lineTo(25.4, 265.9);
        this.ctx.lineTo(25.4, 205.2);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Group/Path
        this.ctx.beginPath();
        this.ctx.moveTo(652.3, 784.2);
        this.ctx.bezierCurveTo(652.3, 797.4, 641.6, 808.2, 628.4, 808.2);
        this.ctx.lineTo(567.7, 808.2);
        this.ctx.lineTo(567.7, 833.6);
        this.ctx.lineTo(628.4, 833.6);
        this.ctx.bezierCurveTo(655.6, 833.6, 677.8, 811.5, 677.8, 784.2);
        this.ctx.lineTo(677.8, 723.5);
        this.ctx.lineTo(652.3, 723.5);
        this.ctx.lineTo(652.3, 784.2);
        this.ctx.closePath();
        this.ctx.fill();

        // layer1/Group/Path
        this.ctx.restore();
        this.ctx.beginPath();
        this.ctx.moveTo(787.1, 421.2);
        this.ctx.bezierCurveTo(800.6, 378.5, 803.2, 329.9, 794.5, 280.7);
        this.ctx.bezierCurveTo(785.7, 230.4, 765.3, 180.7, 737.1, 140.7);
        this.ctx.bezierCurveTo(708.7, 100.3, 673.9, 72.1, 639.3, 61.4);
        this.ctx.bezierCurveTo(636.9, 60.7, 634.4, 62.0, 633.6, 64.4);
        this.ctx.bezierCurveTo(632.9, 66.8, 634.2, 69.3, 636.6, 70.0);
        this.ctx.bezierCurveTo(669.4, 80.2, 702.4, 107.1, 729.7, 145.9);
        this.ctx.bezierCurveTo(757.2, 184.9, 777.1, 233.3, 785.7, 282.2);
        this.ctx.bezierCurveTo(803.1, 381.4, 773.7, 470.2, 704.8, 528.0);
        this.ctx.lineTo(714.8, 479.4);
        this.ctx.bezierCurveTo(715.3, 477.0, 713.7, 474.6, 711.3, 474.1);
        this.ctx.bezierCurveTo(708.9, 473.6, 706.5, 475.1, 706.0, 477.6);
        this.ctx.lineTo(693.5, 538.3);
        this.ctx.bezierCurveTo(693.5, 538.3, 693.5, 538.4, 693.5, 538.4);
        this.ctx.bezierCurveTo(693.5, 538.4, 693.5, 538.5, 693.4, 538.5);
        this.ctx.bezierCurveTo(693.4, 538.7, 693.4, 538.8, 693.4, 538.9);
        this.ctx.bezierCurveTo(693.4, 539.1, 693.4, 539.2, 693.4, 539.3);
        this.ctx.bezierCurveTo(693.4, 539.3, 693.4, 539.4, 693.4, 539.4);
        this.ctx.bezierCurveTo(693.4, 539.5, 693.4, 539.7, 693.4, 539.8);
        this.ctx.bezierCurveTo(693.5, 539.9, 693.5, 540.1, 693.5, 540.2);
        this.ctx.bezierCurveTo(693.5, 540.2, 693.5, 540.2, 693.5, 540.2);
        this.ctx.bezierCurveTo(693.5, 540.2, 693.5, 540.3, 693.5, 540.3);
        this.ctx.bezierCurveTo(693.5, 540.4, 693.6, 540.5, 693.6, 540.6);
        this.ctx.bezierCurveTo(693.6, 540.6, 693.6, 540.7, 693.7, 540.8);
        this.ctx.bezierCurveTo(693.7, 540.8, 693.7, 540.9, 693.7, 541.0);
        this.ctx.bezierCurveTo(693.7, 541.0, 693.8, 541.0, 693.8, 541.1);
        this.ctx.bezierCurveTo(693.8, 541.2, 693.9, 541.3, 693.9, 541.3);
        this.ctx.bezierCurveTo(694.0, 541.5, 694.0, 541.6, 694.1, 541.7);
        this.ctx.bezierCurveTo(694.1, 541.7, 694.2, 541.8, 694.2, 541.8);
        this.ctx.bezierCurveTo(694.2, 541.8, 694.2, 541.8, 694.2, 541.8);
        this.ctx.bezierCurveTo(694.2, 541.9, 694.3, 541.9, 694.3, 541.9);
        this.ctx.bezierCurveTo(694.3, 542.0, 694.4, 542.0, 694.4, 542.0);
        this.ctx.bezierCurveTo(694.5, 542.1, 694.5, 542.2, 694.6, 542.3);
        this.ctx.bezierCurveTo(694.7, 542.4, 694.8, 542.5, 694.8, 542.5);
        this.ctx.bezierCurveTo(694.9, 542.6, 694.9, 542.6, 695.0, 542.6);
        this.ctx.bezierCurveTo(695.0, 542.7, 695.0, 542.7, 695.1, 542.7);
        this.ctx.bezierCurveTo(695.1, 542.8, 695.2, 542.8, 695.2, 542.9);
        this.ctx.bezierCurveTo(695.3, 542.9, 695.4, 543.0, 695.5, 543.0);
        this.ctx.bezierCurveTo(695.6, 543.1, 695.7, 543.2, 695.8, 543.2);
        this.ctx.bezierCurveTo(695.9, 543.3, 695.9, 543.3, 696.0, 543.3);
        this.ctx.bezierCurveTo(696.1, 543.3, 696.2, 543.4, 696.2, 543.4);
        this.ctx.bezierCurveTo(696.4, 543.5, 696.6, 543.5, 696.7, 543.6);
        this.ctx.bezierCurveTo(696.8, 543.6, 696.9, 543.6, 696.9, 543.6);
        this.ctx.bezierCurveTo(696.9, 543.6, 697.0, 543.6, 697.0, 543.6);
        this.ctx.bezierCurveTo(697.0, 543.6, 697.0, 543.6, 697.0, 543.6);
        this.ctx.bezierCurveTo(697.3, 543.7, 697.6, 543.7, 697.9, 543.7);
        this.ctx.bezierCurveTo(697.9, 543.7, 697.9, 543.7, 697.9, 543.7);
        this.ctx.bezierCurveTo(697.9, 543.7, 697.9, 543.7, 697.9, 543.7);
        this.ctx.bezierCurveTo(697.9, 543.7, 698.0, 543.7, 698.0, 543.7);
        this.ctx.bezierCurveTo(698.2, 543.7, 698.4, 543.7, 698.6, 543.7);
        this.ctx.bezierCurveTo(698.7, 543.7, 698.7, 543.6, 698.8, 543.6);
        this.ctx.bezierCurveTo(698.8, 543.6, 698.9, 543.6, 698.9, 543.6);
        this.ctx.lineTo(767.0, 528.1);
        this.ctx.bezierCurveTo(769.5, 527.6, 771.0, 525.2, 770.4, 522.7);
        this.ctx.bezierCurveTo(769.9, 520.3, 767.5, 518.8, 765.0, 519.3);
        this.ctx.lineTo(715.6, 530.6);
        this.ctx.bezierCurveTo(749.5, 500.8, 773.5, 464.1, 787.1, 421.2);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color)
        this.ctx.fill();
        this.ctx.restore();
        // insert text
        this.drawText({ color: this.config.content.color, content: this.config.content.content, x: 72, y: -10, width: 534, height: 107 })

        try {
            await this.insertImage({ x: 72, y: 228, size: Template10_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
