
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 400, height: 517 }
export default class Template01_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 1;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 48,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 4,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    }
                }
            }
        }
    }

    static get sizeQR() {
        return 318;
    }


    async buildGraphic() {

        // layer1/KHung
        this.ctx.save();


        // layer1/Group/Group/Frame
        this.ctx.restore();
        this.ctx.beginPath();

        // layer1/Group/Group/Frame/Path
        this.ctx.moveTo(370.0, 0.0);
        this.ctx.lineTo(29.1, 0.0);
        this.ctx.bezierCurveTo(13.0, 0.0, 0.0, 13.8, 0.0, 30.9);
        this.ctx.lineTo(0.0, 485.4);
        this.ctx.bezierCurveTo(0.0, 502.5, 13.0, 516.3, 29.1, 516.3);
        this.ctx.lineTo(315.7, 516.3);
        this.ctx.bezierCurveTo(317.4, 516.3, 319.0, 515.6, 320.2, 514.4);
        this.ctx.lineTo(397.2, 432.5);
        this.ctx.bezierCurveTo(398.4, 431.2, 399.1, 429.5, 399.1, 427.7);
        this.ctx.lineTo(399.1, 30.9);
        this.ctx.bezierCurveTo(399.1, 13.8, 386.1, 0.0, 370.0, 0.0);
        this.ctx.closePath();

        // layer1/Group/Group/Frame/Path
        this.ctx.moveTo(15.0, 485.4);
        this.ctx.lineTo(15.0, 30.9);
        this.ctx.bezierCurveTo(15.0, 22.6, 21.3, 15.9, 29.1, 15.9);
        this.ctx.lineTo(370.0, 15.9);
        this.ctx.bezierCurveTo(377.8, 15.9, 384.1, 22.6, 384.1, 30.9);
        this.ctx.lineTo(384.1, 414.6);
        this.ctx.lineTo(332.4, 414.6);
        this.ctx.bezierCurveTo(316.4, 414.6, 303.3, 428.4, 303.3, 445.5);
        this.ctx.lineTo(303.3, 500.4);
        this.ctx.lineTo(29.1, 500.4);
        this.ctx.bezierCurveTo(21.3, 500.4, 15.0, 493.7, 15.0, 485.4);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color)
        this.ctx.fill()
        this.drawText({color:this.config.content.color, content: this.config.content.content, x: 42, y: 415, width: 260, height: 80 })

        try {
            await this.insertImage({ x: 42, y: 42, size: Template01_Classic.sizeQR })
        } catch (error) {

        }
        // ctx.moveTo(357.3, 357.3);
        // ctx.lineTo(42.4, 357.3);
        // ctx.lineTo(42.4, 42.4);
        // ctx.lineTo(357.3, 42.4);
        // ctx.lineTo(357.3, 357.3);
        // ctx.closePath();
        // ctx.fillStyle = "rgb(26, 26, 26)";
        // ctx.fill();
        this.ctx.restore();
        return this.canvas.toDataURL()
    }


}
