
import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 400, height: 466 }
export default class Template03_Classic extends CanvasEntity {
    constructor({ image_base64 }) {
        super(width, height, image_base64)
        this.template_id = 1;
        this.template_type = 'classic'
        this.config = {
            ...this.config, ...{
                content: {
                    content: {
                        label: "Text",
                        value: "SCAN ME",
                        font_size: 48,
                        font_family: "Inter",
                        font_weight: "Bold",
                        font_space: 6,
                        type: "text"
                    },
                    color: {
                        label: "Text Color",
                        value: "#ffffff",
                        value_second: "#ffffff",
                        type: "color",
                        gradient: false
                    }
                },
                frame: {
                    color: {
                        label: "Frame Color",
                        value: "#000000",
                        value_second: "#000000",
                        type: "color",
                        gradient: false
                    }
                }
            }
        }
    }

    static get sizeQR() {
        return 252;
    }


    async buildGraphic() {

        // layer1/Group
        this.ctx.save();

        // layer1/Group/Group
        this.ctx.save();

        // layer1/Group/Group/Group
        this.ctx.save();

        // layer1/Group/Group/Group/Frame
        this.ctx.save();
        this.ctx.beginPath();

        // layer1/Group/Group/Group/Frame/Path
        this.ctx.moveTo(248.9, 0.0);
        this.ctx.lineTo(225.4, 0.0);
        this.ctx.lineTo(164.9, 0.0);
        this.ctx.lineTo(150.5, 0.0);
        this.ctx.bezierCurveTo(67.4, 0.0, 0.0, 67.4, 0.0, 150.5);
        this.ctx.lineTo(0.0, 465.1);
        this.ctx.lineTo(164.9, 465.1);
        this.ctx.lineTo(225.4, 465.1);
        this.ctx.lineTo(248.9, 465.1);
        this.ctx.bezierCurveTo(332.0, 465.1, 399.4, 397.8, 399.4, 314.6);
        this.ctx.lineTo(399.4, 150.5);
        this.ctx.bezierCurveTo(399.4, 67.4, 332.0, 0.0, 248.9, 0.0);
        this.ctx.closePath();

        // layer1/Group/Group/Group/Frame/Path
        this.ctx.moveTo(385.7, 232.8);
        this.ctx.bezierCurveTo(385.7, 308.2, 324.3, 369.6, 248.9, 369.6);
        this.ctx.lineTo(225.4, 369.6);
        this.ctx.lineTo(164.9, 369.6);
        this.ctx.lineTo(13.6, 369.6);
        this.ctx.lineTo(13.6, 150.5);
        this.ctx.bezierCurveTo(13.6, 75.0, 75.0, 13.6, 150.5, 13.6);
        this.ctx.lineTo(164.9, 13.6);
        this.ctx.lineTo(225.4, 13.6);
        this.ctx.lineTo(248.9, 13.6);
        this.ctx.bezierCurveTo(324.3, 13.6, 385.7, 75.0, 385.7, 150.5);
        this.ctx.lineTo(385.7, 232.8);
        this.ctx.closePath();
        this.ctx.fillStyle = this.setColor(this.config.frame.color)
        this.ctx.fill();

        this.ctx.restore();
        this.drawText({color:this.config.content.color,content: this.config.content.content, x: 29, y: 384, width: 265, height: 67 })
        this.ctx.restore();

        try {
            await this.insertImage({ x: 74, y: 66, size: Template03_Classic.sizeQR })
        } catch (error) {

        }

        return this.canvas.toDataURL()
    }
}
