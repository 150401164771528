import Template01_Classic from "./classic/template_01";
import Template02_Classic from "./classic/template_02";
import Template03_Classic from "./classic/template_03";
import Template04_Classic from "./classic/template_04";
import Template05_Classic from "./classic/template_05";
import Template06_Classic from "./classic/template_06";
import Template07_Classic from "./classic/template_07";
import Template08_Classic from "./classic/template_08";
import Template09_Classic from "./classic/template_09";
import Template10_Classic from "./classic/template_10";
import Template11_Classic from "./classic/template_11";
import Template12_Classic from "./classic/template_12";
import Template13_Classic from "./classic/template_13";

export default function GetTheme({ id, type }) {
    if (type == 'classic') {
        switch (id) {
            case 1:
                return Template01_Classic
            case 2:
                return Template02_Classic
            case 3:
                return Template03_Classic
            case 4:
                return Template04_Classic
            case 5:
                return Template05_Classic
            case 6:
                return Template06_Classic
            case 7:
                return Template07_Classic
            case 8:
                return Template08_Classic
            case 9:
                return Template09_Classic
            case 10:
                return Template10_Classic
            case 11:
                return Template11_Classic
            case 12:
                return Template12_Classic
            case 13:
                return Template13_Classic
        }
    }
}