import CanvasEntity from "../canvas.entity";
const { width, height } = { width: 626, height: 840 };
export default class Template13_Classic extends CanvasEntity {
  constructor({ image_base64 }) {
    super(width, height, image_base64);
    this.template_id = 1;
    this.template_type = "classic";
    this.config = {
      ...this.config,
      ...{
        content: {
          content: {
            label: "Text",
            value: "SCAN ME",
            font_size: 120,
            font_family: "Inter",
            font_weight: "Bold",
            font_space: 6,
            type: "text",
          },
          color: {
            label: "Text Color",
            value: "#000",
            value_second: "#000",
            type: "color",
            gradient: false,
          },
        },
        frame: {
          background: {
            label: "Background Color",
            value: "#00000000",
            value_second: "#00000000",
            type: "color",
            gradient: false,
          },
        },
      },
    };
  }

  static get sizeQR() {
    return 626;
  }
  

  async buildGraphic() {
    this.ctx.beginPath();
    this.ctx.moveTo(626, 840.0);
    this.ctx.lineTo(0.0, 840.0);
    this.ctx.lineTo(0.0, 0.0);
    this.ctx.lineTo(626, 0.0);
    this.ctx.lineTo(626, 840.0);
    this.ctx.closePath();
    this.ctx.fillStyle = this.setColor(this.config.frame.background);
    this.ctx.fill();
    this.ctx.beginPath();


    
    // insert text
    this.drawText({
      color: this.config.content.color,
      content: this.config.content.content,
      x: 0,
      y: 640,
      width: 625,
      height: 164,
    });

    try {
      await this.insertImage({ x: 0, y: 0, size: Template13_Classic.sizeQR });
    } catch (error) {}

    return this.canvas.toDataURL();
  }
}
